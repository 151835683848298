import React, { useEffect, useRef } from 'react';
import '../styles/PlaylistDropdown.css';

const PlaylistDropdown = ({
  playlists,
  selectedPlaylist,
  onPlaylistSelect,
  isDropdownOpen,
  toggleDropdown,
  onClose
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div className="playlist-dropdown" ref={dropdownRef}>
      <button className="playlist-dropdown-button" onClick={toggleDropdown}>
        <span className="playlist-dropdown-label">
          {selectedPlaylist || 'Playlist'}
        </span>
        <span className="playlist-dropdown-icon">▼</span>
      </button>
      <div className={`playlist-dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
        <a 
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPlaylistSelect(null);
          }}
          className={!selectedPlaylist ? 'active' : ''}
        >
          All Playlists
        </a>
        {playlists.map((playlist) => (
          <a
            key={playlist}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPlaylistSelect(playlist);
            }}
            className={selectedPlaylist === playlist ? 'active' : ''}
          >
            {playlist}
          </a>
        ))}
      </div>
    </div>
  );
};

export default PlaylistDropdown;