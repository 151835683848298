import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ChannelHeader from './ChannelHeader';
import { QRCodeSVG } from 'qrcode.react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';
import { Printer, QrCode, Eye, EyeOff, Pencil, Share2, Check, X, Plus, Share, Trash2 } from 'lucide-react';
import { FaPlus, FaMinus, FaPlay, FaPause, FaCommentDots, FaGripVertical } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import PlaceholderImage from './site_images/player_placeholder.png';
import QRCodeModal from './QRCodeModal';
import '../styles/ChannelPage.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet';
import PrintableQRCode from './PrintableQRCode';
import ShareModal from './ShareModal';
import { 
  getBrandingAudioUrl,
  getPublicApiEndpoint,
  getPrivateApiEndpoint,
  getPlaylistApiEndpoint,
  getAudioUpdateEndpoint,
  getPlaylistEndpoint
} from '../config/api';
import PlaylistDropdown from './PlaylistDropdown';
import '../styles/PlaylistDropdown.css';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const ITEMS_PER_PAGE = 40;
const AUDIO_PLAY_EVENT = 'audioPlayEvent';
const BRANDING_AUDIO_URL = getBrandingAudioUrl();
const PUBLIC_API = getPublicApiEndpoint();
const PRIVATE_API = getPrivateApiEndpoint();
const PLAYLIST_API = getPlaylistApiEndpoint();

const DraggableAudioTable = ({ 
  displayedAudioFiles = [],
  isOwner = false,
  playingAudioId = null,
  expandedRows = {},
  audioStates = {},
  showQRCode = false,
  setShowQRCode = () => {},
  qrCodeData = null,
  setQrCodeData = () => {},
  setExpandedRows = () => {},
  onDragEnd = () => {},
  onPlayPause = () => {},
  onToggleExpand = () => {},
  onToggleVisibility = () => {},
  handleProgressClick = () => {},
  handleProgressDrag = () => {},
  audioRefs = {},
  hasBeenManuallyReordered = false,
  filterButtons,
  getShareUrl = () => {},
  editingTopic = null,
  setEditingTopic = () => {},
  editingText = '',
  setEditingText = () => {},
  handleTopicEdit = () => {},
  setShareUrl = () => {},
  setIsShareModalOpen = () => {},
  isShareModalOpen = false,
  selectedFile = null,
  setSelectedFile = () => {},
  searchQuery = ''
}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <table className="audio-table">
      <thead className="table-header">
        <tr>
          <th className="drag-handle-col"></th>
          <th className="sort-header" colSpan="2">
            <div className="sort-controls">
              {filterButtons}
            </div>
          </th>
        </tr>
      </thead>
      <Droppable 
        droppableId="audioFiles" 
        direction="vertical"
        mode="standard"
      >
        {(provided) => (
          <tbody 
            {...provided.droppableProps} 
            ref={provided.innerRef}
            style={{ 
              position: 'relative',
              zIndex: 0 
            }}
          >
            {displayedAudioFiles.map((file, index) => (
              <Draggable
                key={file.id}
                draggableId={`file-${file.id}`}
                index={index}
                isDragDisabled={!isOwner}
              >
                {(provided, snapshot) => (
                  <tr
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`audio-item ${snapshot.isDragging ? 'is-dragging' : ''}`}
                    style={{
                      ...provided.draggableProps.style,
                      zIndex: snapshot.isDragging ? 999 : 'auto'
                    }}
                  >
               <td {...provided.dragHandleProps} className="drag-handle-cell">
  {isOwner && (
    <FaGripVertical className="drag-handle" width={32} height={32} />
  )}
</td>
                    <td className="title-cell">
                      <div className="title-wrapper">
                        <button onClick={() => onPlayPause(file.id, file.audioUrl)} className="play-button">
                          {playingAudioId === file.id ? <FaPause className="play-icon" /> : <FaPlay className="play-icon" />}
                        </button>
                        <div className="topic-section">
                          {editingTopic === file.id ? (
                            <form 
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleTopicEdit(file);
                              }}
                              style={{ flex: 1 }}
                              className="edit-form"
                            >
                              <div className="edit-input-group">
                                <input
                                  type="text"
                                  value={editingText}
                                  onChange={(e) => setEditingText(e.target.value)}
                                  autoFocus
                                  className="edit-input"
                                />
                              </div>
                            </form>
                          ) : (
                            <span>{file.topic}</span>
                          )}
                          {isOwner && (
                            <div className="edit-controls">
                              {editingTopic === file.id ? (
                                <div className="edit-buttons">
                                  <button 
                                    onClick={() => handleTopicEdit(file)}
                                    className="edit-save-button"
                                    title="Save"
                                  >
                                    <Check size={15} strokeWidth={2} />
                                  </button>
                                  <button 
                                    onClick={() => {
                                      setEditingTopic(null);
                                      setEditingText('');
                                    }}
                                    className="edit-cancel-button"
                                    title="Cancel"
                                  >
                                    <X size={15} strokeWidth={2} />
                                  </button>
                                </div>
                              ) : (
                                <button
                                  onClick={() => {
                                    setEditingTopic(file.id);
                                    setEditingText(file.topic);
                                  }}
                                  className="edit-button"
                                  title="Edit title"
                                >
                                  <Pencil size={15} strokeWidth={2} />
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="control-buttons">
                          <button
                            onClick={() => onToggleExpand(file.id)}
                            className="details-button"
                            title="Show details"
                          >
                            {expandedRows[file.id] ? <FaMinus /> : <FaPlus />}
                          </button>
                          <button
                            onClick={() => {
                              setShareUrl(getShareUrl(file));
                              setIsShareModalOpen(true);
                            }}
                            className="share-button"
                            title="Share"
                          >
                            <Share size={15} strokeWidth={2} />
                          </button>
                          {isOwner && (
                            <button
                              onClick={() => onToggleVisibility(file)}
                              className="visibility-button"
                              disabled={file.isUpdating}
                              title={file.isPublic === false ? "Make Public" : "This will hide the audio file from your channel"}
                            >
                              {file.isPublic === false ? <EyeOff size={15} strokeWidth={2} /> : <Eye size={15} strokeWidth={2} />}
                            </button>
                          )}
                        </div>
                      </div>
                      <div 
                        className="progress-container"
                        onClick={(e) => handleProgressClick(e, file.id, audioRefs[file.id])}
                        onMouseMove={(e) => handleProgressDrag(e, file.id, audioRefs[file.id])}
                      >
                        <div 
                          className="progress-bar" 
                          style={{ width: `${(audioStates[file.id]?.currentTime / audioStates[file.id]?.duration) * 100 || 0}%` }} 
                        />
                      </div>
                      {expandedRows[file.id] && (
                        <div className="expanded-content">
                          <div className="summary-section">
                            <strong>Summary:</strong> {file.summary || 'No summary available'}
                          </div>
                          <div className="share-buttons">
                            <div className="social-icons">
                              <FacebookShareButton 
                                url={getShareUrl(file)}
                                quote={`${file.topic} by ${file.username}`}
                              >
                                <FacebookIcon size={34} round />
                              </FacebookShareButton>

                              <a 
                                href={`https://x.com/intent/tweet?url=${encodeURIComponent(getShareUrl(file))}&text=${encodeURIComponent(`${file.topic} by ${file.username}`)}&hashtags=Biglio,Audio`}
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="x-button"
                              >
                                <FaXTwitter size={24} color="white" />
                              </a>

                              <LinkedinShareButton
                                url={getShareUrl(file)}
                                title={file.topic}
                                summary={file.summary || `Listen to "${file.topic}" by ${file.username} on Biglio`}
                              >
                                <LinkedinIcon size={34} round />
                              </LinkedinShareButton>

                              <EmailShareButton
                                url={getShareUrl(file)}
                                subject={`Listen to "${file.topic}" on Biglio`}
                                body={`Check out this audio: "${file.topic}" by ${file.username}\n\n`}
                              >
                                <EmailIcon size={34} round />
                              </EmailShareButton>

                              <a 
                                href={`sms:?&body=${encodeURIComponent(`Listen to "${file.topic}" on Biglio: ${getShareUrl(file)}`)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="sms-button"
                              >
                                <FaCommentDots size={24} color="white" />
                              </a>

                              <button 
                                onClick={() => {
                                  setShowQRCode(true);
                                  setQrCodeData({
                                    url: `${window.location.origin}/audio-player?audioUrl=${encodeURIComponent(file.audioUrl)}&topic=${encodeURIComponent(file.topic)}&username=${encodeURIComponent(file.username)}`
                                  });
                                }}
                                className="qr-button"
                                aria-label="Show QR Code"
                              >
                                <QrCode size={34} color="white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </table>
  </DragDropContext>
);

const ChannelPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, user, getFormattedToken, refreshSession, signOut } = useContext(AuthContext);
  const [audioFiles, setAudioFiles] = useState([]);
  const [displayedAudioFiles, setDisplayedAudioFiles] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [channelUser, setChannelUser] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [audioStates, setAudioStates] = useState({});
  const audioRefs = useRef({});
  const brandingAudioRef = useRef(new Audio(BRANDING_AUDIO_URL));
  const [hasBeenManuallyReordered, setHasBeenManuallyReordered] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [visibilityFilter, setVisibilityFilter] = useState('all');
  const [editingTopic, setEditingTopic] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const isOwner = isAuthenticated && user?.username === username;

  const fetchChannelUser = useCallback(async () => {
    try {
      const response = await fetch(`${PUBLIC_API}/users/lookup?username=${username}`);
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Channel not found');
        }
        throw new Error('Error looking up user');
      }
      const userData = await response.json();
      setChannelUser(userData);
      return userData.userid;
    } catch (err) {
      console.error('Error fetching channel user:', err);
      setError('Channel not found');
      return null;
    }
  }, [username]);

  const fetchUserAudioFiles = useCallback(async (userId) => {
    if (!userId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${PRIVATE_API}/audio-files-for-my-profile?userid=${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch audio files: ${response.status}`);
      }
      const data = await response.json();
      const sortedFiles = data
        .map(file => ({
          ...file,
          isLoaded: false,
          topic: file.topic.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
        }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setAudioFiles(sortedFiles);
      updateDisplayedFiles(sortedFiles, 1);
    } catch (error) {
      console.error('Error fetching audio files:', error);
      setError(`Failed to fetch audio files. ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPlaylists = useCallback(async (userId) => {
    if (!userId) return;
    try {
      const response = await fetch(`${PLAYLIST_API}/playlists?userid=${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch playlists: ${response.status}`);
      }
      const data = await response.json();
      setPlaylists(data.playlists || []);
    } catch (error) {
      console.error('Error fetching playlists:', error);
      setPlaylists([]);
    }
  }, []);

  useEffect(() => {
    const initializeChannel = async () => {
      setLoading(true);
      setError(null);
      try {
        let userId;
        if (isOwner && user?.userid) {
          userId = user.userid;
          setChannelUser({
            username: user.username,
            userid: user.userid,
            accessToken: user.accessToken
          });
        } else {
          userId = await fetchChannelUser();
        }
        if (userId) {
          await Promise.all([
            fetchUserAudioFiles(userId),
            fetchPlaylists(userId)
          ]);
        }
      } catch (err) {
        console.error('Error initializing channel:', err);
        setError('Failed to load channel');
      } finally {
        setLoading(false);
      }
    };
    if (username) {
      initializeChannel();
    }
  }, [username, isOwner, user, fetchChannelUser, fetchUserAudioFiles, fetchPlaylists]);

  const updateDisplayedFiles = (files, page, selectedPlaylist = playlistName) => {
    const filteredFiles = selectedPlaylist
      ? files.filter(file => file.playlistName === selectedPlaylist)
      : files;
    const startIndex = 0;
    const endIndex = page * ITEMS_PER_PAGE;
    const newDisplayedFiles = filteredFiles.slice(startIndex, endIndex).map(file => ({
      ...file,
      isLoaded: true,
      shareUrl: `/channel/${username}/audio/${file.id}`
    }));
    setDisplayedAudioFiles(newDisplayedFiles);
    setHasMore(endIndex < filteredFiles.length);
    setCurrentPage(page);
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    updateDisplayedFiles(audioFiles, nextPage);
  };

  const handlePlaylistChange = (e) => {
    const newPlaylistName = e.target.value;
    setPlaylistName(newPlaylistName);
    setCurrentPage(1);
    updateDisplayedFiles(audioFiles, 1, newPlaylistName);
  };

  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const formatTime = (time) => {
    if (isNaN(time)) return '00:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const preloadAudioDurations = () => {
    displayedAudioFiles.forEach(file => {
      const audio = new Audio(file.audioUrl);
      audio.addEventListener('loadedmetadata', () => {
        setAudioStates(prev => ({
          ...prev,
          [file.id]: { ...prev[file.id], duration: audio.duration }
        }));
      });
    });
  };

  useEffect(() => {
    preloadAudioDurations();
  }, [displayedAudioFiles]);

  const getOrCreateAudioElement = (fileId, audioUrl) => {
    if (!audioRefs.current[fileId]) {
      const audio = new Audio(audioUrl);
      audio.addEventListener('timeupdate', () => {
        setAudioStates(prev => ({
          ...prev,
          [fileId]: {
            currentTime: audio.currentTime,
            duration: audio.duration
          }
        }));
      });
      audioRefs.current[fileId] = audio;
    }
    return audioRefs.current[fileId];
  };

  const playPauseAudio = (id, url) => {
    // If clicking the currently playing audio, just pause/resume it
    if (playingAudioId === id) {
      const currentAudio = audioRefs.current[id];
      if (currentAudio) {
        if (currentAudio.paused) {
          currentAudio.play();
        } else {
          currentAudio.pause();
        }
        setPlayingAudioId(currentAudio.paused ? null : id);
        setCurrentlyPlaying(currentAudio.paused ? null : currentAudio);
      }
      return;
    }

    // Stop any other playing audio before playing new one
    if (playingAudioId && playingAudioId !== id) {
      if (audioRefs.current[playingAudioId]) {
        audioRefs.current[playingAudioId].pause();
      }
      if (brandingAudioRef.current) {
        brandingAudioRef.current.pause();
      }
    }

    // Play new audio
    setPlayingAudioId(id);
    
    // If the audio already exists and was paused, resume it
    if (audioRefs.current[id]) {
      audioRefs.current[id].play();
      setCurrentlyPlaying(audioRefs.current[id]);
      return;
    }

    // Otherwise, start with branding audio and then play the new audio
    brandingAudioRef.current.play().then(() => {
      brandingAudioRef.current.onended = () => {
        const audio = new Audio(url);
        audioRefs.current[id] = audio;
        
        audio.addEventListener('loadedmetadata', () => {
          setAudioStates(prev => ({
            ...prev,
            [id]: { ...prev[id], duration: audio.duration }
          }));
        });

        audio.addEventListener('timeupdate', () => {
          setAudioStates(prev => ({
            ...prev,
            [id]: { ...prev[id], currentTime: audio.currentTime }
          }));
        });

        audio.addEventListener('ended', () => {
          setPlayingAudioId(null);
          setCurrentlyPlaying(null);
        });

        audio.play();
        setCurrentlyPlaying(audio);
      };
    }).catch(error => {
      console.error('Playback failed:', error);
      setError('Playback failed. Please try again.');
    });
  };

  useEffect(() => {
    const handleOtherPlayerStart = (event) => {
      if (event.detail.playerId !== playingAudioId) {
        if (audioRefs.current[playingAudioId]) {
          audioRefs.current[playingAudioId].pause();
        }
        brandingAudioRef.current.pause();
        setPlayingAudioId(null);
      }
    };

    window.addEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
    return () => window.removeEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
  }, [playingAudioId]);

  const handleDragEnd = (result) => {
    if (!result.destination || !result.source) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    const newFiles = Array.from(displayedAudioFiles);
    const [removed] = newFiles.splice(sourceIndex, 1);
    newFiles.splice(destinationIndex, 0, removed);

    setSortConfig(null);
    setHasBeenManuallyReordered(true);
    setDisplayedAudioFiles(newFiles);

    const updatedAudioFiles = [...audioFiles];
    const fileToMove = updatedAudioFiles.find(f => f.id === removed.id);
    const oldIndex = updatedAudioFiles.indexOf(fileToMove);
    updatedAudioFiles.splice(oldIndex, 1);
    const newIndex = updatedAudioFiles.findIndex(f => f.id === newFiles[destinationIndex].id);
    updatedAudioFiles.splice(newIndex, 0, fileToMove);
    setAudioFiles(updatedAudioFiles);
  };

  const getShareUrl = (file) => {
    if (!file) return '';
    if (file.shortCode) {
      const path = `/s/${file.shortCode}`;
      return window.location.hostname === 'localhost' 
        ? `https://biglio.com${path}` 
        : `${window.location.origin}${path}`;
    }
    // Fallback in case shortCode doesn't exist
    return `${window.location.origin}/audio-player?id=${file.id}`;
  };

  const handleTopicEdit = async (audioFile) => {
    try {
        setDisplayedAudioFiles(files => 
            files.map(file => 
                file.id === audioFile.id 
                    ? { ...file, isUpdating: true }
                    : file
            )
        );

        const response = await fetch(
            getAudioUpdateEndpoint(),
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    audioId: audioFile.id,
                    updates: {
                        topic: editingText.trim()
                    }
                })
            }
        );

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || `Request failed: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
            setDisplayedAudioFiles(files => 
                files.map(file => 
                    file.id === audioFile.id 
                        ? { ...file, topic: editingText.trim(), isUpdating: false }
                        : file
                )
            );
            setAudioFiles(files => 
                files.map(file => 
                    file.id === audioFile.id 
                        ? { ...file, topic: editingText.trim() }
                        : file
                )
            );
            setEditingTopic(null);
            setEditingText('');
        } else {
            throw new Error(data.message || 'Update failed');
        }
    } catch (error) {
        console.error('Topic update error:', error);
        setError(error.message || 'Failed to update topic');
        setDisplayedAudioFiles(files => 
            files.map(file => 
                file.id === audioFile.id 
                    ? { ...file, isUpdating: false }
                    : file
            )
        );
    }
  };

  const handleProgressClick = (e, fileId, audioElement) => {
    if (!audioElement) return;
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const x = e.type === 'touchstart' ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
    const percentage = x / rect.width;
    const newTime = percentage * audioElement.duration;
    audioElement.currentTime = newTime;
  };

  const handleProgressDrag = (e, fileId, audioElement) => {
    if (!audioElement) return;
    if (e.buttons === 1 || e.type === 'touchmove') {
      handleProgressClick(e, fileId, audioElement);
    }
  };

  useEffect(() => {
    return () => {
      if (currentlyPlaying) {
        currentlyPlaying.pause();
        currentlyPlaying.currentTime = 0;
      }
      if (brandingAudioRef.current) {
        brandingAudioRef.current.pause();
        brandingAudioRef.current.currentTime = 0;
      }
      Object.values(audioRefs.current).forEach(audio => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
      });
    };
  }, []);

  const handlePlay = (audioElement, audioId) => {
    if (currentlyPlaying && currentlyPlaying !== audioElement) {
      currentlyPlaying.pause();
      currentlyPlaying.currentTime = 0;
    }
    
    if (audioElement.paused) {
      audioElement.play();
      setCurrentlyPlaying(audioElement);
    } else {
      audioElement.pause();
      setCurrentlyPlaying(null);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePlaylistSelect = (playlistName) => {
    setSelectedPlaylist(playlistName);
    setIsDropdownOpen(false);
    setCurrentPage(1);

    // Filter audio files based on playlist
    const filteredFiles = audioFiles.filter(file => {
      if (!playlistName || playlistName === 'All Playlists') return true; // Show all if no playlist selected
      return file.playlistName === playlistName;
    });

    setDisplayedAudioFiles(filteredFiles.slice(0, ITEMS_PER_PAGE));
    setHasMore(filteredFiles.length > ITEMS_PER_PAGE);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleVisibility = async (audioFile) => {
    try {
      console.log('Attempting to toggle visibility for:', audioFile.id);
      if (!audioFile.id) {
        throw new Error('Invalid audio file ID');
      }

      // Set updating state to show loading
      setDisplayedAudioFiles(files => 
        files.map(file => 
          file.id === audioFile.id 
            ? { ...file, isUpdating: true }
            : file
        )
      );
      
      const requestBody = {
        audioId: audioFile.id,
        updates: {
          isPublic: !(audioFile.isPublic ?? true)
        }
      };
      console.log('Sending request:', requestBody);

      const response = await fetch(
        getAudioUpdateEndpoint(),
        {
          method: 'PATCH',
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(requestBody)
        }
      );
      
      let data;
      try {
        data = await response.json();
      } catch (e) {
        console.error('Failed to parse response:', e);
        throw new Error('Invalid server response');
      }

      if (!response.ok) throw new Error(`Failed to update visibility: ${data.message || response.status}`);
      
      if (data.success) {
        const newIsPublic = !(audioFile.isPublic ?? true);
        // Update displayed files
        setDisplayedAudioFiles(files => 
          files.map(file => 
            file.id === audioFile.id 
              ? { ...file, isPublic: newIsPublic, isUpdating: false }
            : file
          )
        );
        // Update main audio files array
        setAudioFiles(files =>
          files.map(file =>
            file.id === audioFile.id
              ? { ...file, isPublic: newIsPublic }
              : file
          )
        );
      } else {
        throw new Error(data.message || 'Update failed');
      }
    } catch (error) {
      console.error('Visibility update error:', {
        message: error.message,
        stack: error.stack,
        audioFile
      });
      // Reset updating state on error
      setDisplayedAudioFiles(files => 
        files.map(file => 
          file.id === audioFile.id 
            ? { ...file, isUpdating: false }
            : file
        )
      );
      setError(error.message);
    }
  };

  const onToggleVisibility = (file) => {
    console.log('File visibility:', file.isPublic);
    toggleVisibility(file);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <div className="loading-text">Loading channel...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error">
        <p>{error}</p>
        <button 
          onClick={() => navigate('/')} 
          className="return-home-button"
        >
          Return Home
        </button>
      </div>
    );
  }

  return (
    <div className="channel-page">
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{username}'s Channel - Biglio</title>
        <meta name="description" content={`Check out ${username}'s audio content channel on Biglio. Listen to professional audio content.`} />
        
        {/* OpenGraph Tags */}
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={`${username}'s Channel - Biglio`} />
        <meta property="og:description" content={`Check out ${username}'s audio content channel on Biglio.`} />
        <meta property="og:url" content={`https://biglio.com/channel/${username}`} />
        {channelUser?.profileImage && (
          <meta property="og:image" content={channelUser.profileImage} />
        )}
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`${username}'s Channel - Biglio`} />
        <meta name="twitter:description" content={`Check out ${username}'s audio content channel on Biglio.`} />
        {channelUser?.profileImage && (
          <meta name="twitter:image" content={channelUser.profileImage} />
        )}

        {/* Schema.org markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "ProfilePage",
            "name": `${username}'s Channel`,
            "description": `Audio content channel by ${username} on Biglio`,
            "url": `https://biglio.com/channel/${username}`,
            "mainEntity": {
              "@type": "Person",
              "name": username,
              "url": `https://biglio.com/channel/${username}`
            },
            "about": {
              "@type": "AudioObject",
              "name": "Channel Audio Content",
              "description": `Audio content created by ${username}`,
              "contentUrl": `https://biglio.com/channel/${username}`
            }
          })}
        </script>
      </Helmet>

      <ChannelHeader 
        user={channelUser}
        playlists={playlists}
        onPlaylistChange={handlePlaylistChange}
        playlistName={playlistName}
        isOwner={isOwner}
        onError={(err) => {
          console.error('Profile error:', err);
          setError(err.message || 'An error occurred with the profile');
        }}
      />
      <main>
        <section>
          {loading && <div className="loading">Loading...</div>}
          {error && <p className="error-message">{error}</p>}
          {!loading && (
            <div className="playlist-search-row">
              <PlaylistDropdown
                playlists={playlists}
                selectedPlaylist={selectedPlaylist}
                onPlaylistSelect={handlePlaylistSelect}
                isDropdownOpen={isDropdownOpen}
                toggleDropdown={toggleDropdown}
                onSort={(sortValue) => {
                  const sortedFiles = [...displayedAudioFiles].sort((a, b) => {
                    if (sortValue === 'topic') {
                      return a.topic.localeCompare(b.topic);
                    }
                    if (sortValue === 'createdAt') {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    }
                    return 0;
                  });
                  setDisplayedAudioFiles(sortedFiles);
                }}
              />
              <input
                type="text"
                placeholder="Search by title"
                value={searchQuery}
                onChange={handleSearch}
                className="search-input"
              />
            </div>
          )}
          {!loading && !error && displayedAudioFiles.length === 0 && (
            <div className="no-audio-message welcome-message">
              {isOwner ? (
                <>
                  <h2>Welcome to your channel, <strong>{username}</strong>!</h2>
                  <p>This is where your ideas come to life. With Biglio's AI-powered tools, creating professional audio content has never been easier or more fun.</p>
                  <p>Be sure to fill out your bio and profile image above!</p>
                  <ul>
                    <p><strong>With Biglio, you can:</strong></p>
                    <li>✨ Transform your ideas into professional audio content in minutes</li>
                    <li>🌟 Build your own content channel and grow your audience</li>
                    <li>📚 Create engaging content series delivered to your listeners</li>
                    <li>🔗 Connect physical products to digital content with QR codes</li>
                  </ul>
                  <div className="channel-intro">
                    <a href="/create" className="start-creating-button">Start creating amazing content now!</a>
                  </div>
                </>
              ) : (
                <p>This channel has no audio files yet.</p>
              )}
            </div>
          )}
          <DraggableAudioTable
            displayedAudioFiles={displayedAudioFiles.filter(file => {
              if (visibilityFilter === 'public') return file.isPublic !== false;
              if (visibilityFilter === 'private') return file.isPublic === false;
              return file.topic.toLowerCase().includes(searchQuery.toLowerCase());
            })}
            isOwner={isOwner}
            playingAudioId={playingAudioId}
            expandedRows={expandedRows}
            audioStates={audioStates}
            showQRCode={showQRCode}
            setShowQRCode={setShowQRCode}
            qrCodeData={qrCodeData}
            setQrCodeData={setQrCodeData}
            setExpandedRows={setExpandedRows}
            onDragEnd={handleDragEnd}
            onPlayPause={playPauseAudio}
            onToggleExpand={toggleRowExpansion}
            onToggleVisibility={onToggleVisibility}
            handleProgressClick={handleProgressClick}
            handleProgressDrag={handleProgressDrag}
            audioRefs={audioRefs.current}
            hasBeenManuallyReordered={hasBeenManuallyReordered}
            getShareUrl={getShareUrl}
            editingTopic={editingTopic}
            setEditingTopic={setEditingTopic}
            editingText={editingText}
            setEditingText={setEditingText}
            handleTopicEdit={handleTopicEdit}
            setShareUrl={setShareUrl}
            setIsShareModalOpen={setIsShareModalOpen}
            isShareModalOpen={isShareModalOpen}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
          {hasMore && !loading && (
            <div className="load-more-container">
              <button onClick={loadMore} className="load-more-button">
                Load More
              </button>
            </div>
          )}
        </section>
      </main>
      <ShareModal 
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        shareUrl={shareUrl}
        setShowQRCode={setShowQRCode}
        setQrCodeData={setQrCodeData}
        topic={displayedAudioFiles.find(f => f.audioUrl === shareUrl.split('audioUrl=')[1]?.split('&')[0])?.topic || ''}
        audioId={displayedAudioFiles.find(f => f.audioUrl === shareUrl.split('audioUrl=')[1]?.split('&')[0])?.id || ''}
        audioUrl={shareUrl.split('audioUrl=')[1]?.split('&')[0] || ''}
        username={username}
      />
      {showQRCode && qrCodeData && (
        <QRCodeModal
          url={qrCodeData.url}
          onClose={() => {
            setShowQRCode(false);
            setQrCodeData(null);
          }}
        />
      )}
    </div>
  );
};

export default ChannelPage;