import React, { useEffect, useState, useContext } from 'react';
import { X, MessageSquare } from 'lucide-react';
import { 
  FacebookShareButton, 
  LinkedinShareButton, 
  EmailShareButton,
  TwitterShareButton 
} from 'react-share';
import { 
  FaFacebook, 
  FaLinkedin, 
  FaEnvelope, 
  FaQrcode
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { toast } from 'react-hot-toast';
import { getShortUrlEndpoint, getShortUrlLookupEndpoint } from '../config/api';
import '../styles/ShareModal.css';

// Helper functions
const getPublicUrl = (url) => {
  if (!url) return '';
  if (url.startsWith('/')) {
    return window.location.hostname === 'localhost' 
      ? `https://biglio.com${url}`
      : `${window.location.origin}${url}`;
  }
  if (url.includes('localhost')) {
    return url.replace('http://localhost:61727', 'https://biglio.com');
  }
  return url;
};

const ShareModal = ({ 
  isOpen, 
  onClose, 
  shareUrl, 
  topic,
  setShowQRCode,
  setQrCodeData,
  username,
  audioId
}) => {
  const [shortUrl, setShortUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchShortUrl = async (audioId) => {
    try {
      const response = await fetch(getShortUrlLookupEndpoint(audioId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch short URL');
      }

      const data = await response.json();
      if (data.shortUrl) {
        setShortUrl(data.shortUrl);
      } else {
        throw new Error('Short URL not found');
      }
    } catch (error) {
      console.error('Error fetching short URL:', error);
      setShortUrl(getPublicUrl(shareUrl)); // Fallback to full URL
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && shareUrl) {
      fetchShortUrl(audioId);
    }
  }, [isOpen, shareUrl]);

  if (!isOpen) return null;

  const publicShareUrl = shortUrl || getPublicUrl(shareUrl);
  
  // Format share messages - simplified to avoid duplication
  const shareMessage = topic ? 
    `🎧 Listen to "${topic}" from ${username} on Biglio ${publicShareUrl}` : 
    `🎧 Listen to this from ${username} on Biglio ${publicShareUrl}`;
    
  // Social media specific messages (without URL)
  const socialMessage = topic ? 
    `🎧 Listen to "${topic}" from ${username} on Biglio` : 
    `🎧 Listen to this from ${username} on Biglio`;

  // Use shareMessage directly instead of combining with URL again
  const description = shareMessage;
  
  const handleQRCodeClick = () => {
    setShowQRCode(true);
    setQrCodeData({
      url: publicShareUrl
    });
    onClose();
  };

  const handleMessageShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: undefined,
          text: shareMessage,
          url: undefined
        });
      } else {
        await navigator.clipboard.writeText(shareMessage);
        toast.success('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      try {
        await navigator.clipboard.writeText(shareMessage);
        toast.success('Link copied to clipboard!');
      } catch (err) {
        toast.error('Unable to share or copy link');
      }
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(publicShareUrl);
    toast.success('Link copied to clipboard!');
  };

  return (
    <div 
      className="modal-overlay share-modal"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div 
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-white text-2xl">Share</h2>
          <button 
            onClick={onClose} 
            className="close-button"
          >
            <X size={24} />
          </button>
        </div>

        <div className="copy-link-section">
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={isLoading ? 'Generating share link...' : publicShareUrl}
              readOnly
              className="bg-transparent text-white flex-grow outline-none text-sm"
            />
            <button
              onClick={handleCopyToClipboard}
              className="copy-button"
              disabled={isLoading}
            >
              Copy
            </button>
          </div>
        </div>

        <div className="share-grid">
          <div className="bg-transparent">
            <FacebookShareButton 
              url={publicShareUrl}
              quote={socialMessage}
              hashtag="#Biglio"
              disabled={isLoading}
            >
              <div className="share-button-wrapper">
                <div className="share-icon facebook">
                  <FaFacebook size={24} />
                </div>
                <span className="share-label">Facebook</span>
              </div>
            </FacebookShareButton>
          </div>

          <div className="bg-transparent">
            <TwitterShareButton 
              url={publicShareUrl}
              title={socialMessage}
              hashtags={['Biglio']}
              via="Biglio"
              disabled={isLoading}
            >
              <div className="share-button-wrapper">
                <div className="share-icon twitter">
                  <FaXTwitter size={24} />
                </div>
                <span className="share-label">Twitter</span>
              </div>
            </TwitterShareButton>
          </div>

          <div className="bg-transparent">
            <LinkedinShareButton 
              url={publicShareUrl}
              title={socialMessage}
              summary={socialMessage}
              source="Biglio"
              disabled={isLoading}
            >
              <div className="share-button-wrapper">
                <div className="share-icon linkedin">
                  <FaLinkedin size={24} />
                </div>
                <span className="share-label">LinkedIn</span>
              </div>
            </LinkedinShareButton>
          </div>

          <div className="bg-transparent">
            <EmailShareButton 
              url=""
              subject={topic ? `Listen to "${topic}" on Biglio` : 'Listen to this on Biglio'}
              body={shareMessage}
              disabled={isLoading}
            >
              <div className="share-button-wrapper">
                <div className="share-icon email">
                  <FaEnvelope size={24} />
                </div>
                <span className="share-label">Email</span>
              </div>
            </EmailShareButton>
          </div>

          <button 
            className="bg-transparent border-none"
            disabled={isLoading}
          >
            <div className="share-button-wrapper">
              <div 
                className="share-icon message"
                onClick={handleMessageShare}
                role="button"
                tabIndex={0}
              >
                <MessageSquare size={24} />
              </div>
              <span className="share-label">Message</span>
            </div>
          </button>

          <button 
            className="bg-transparent border-none"
            onClick={handleQRCodeClick}
            disabled={isLoading}
          >
            <div className="share-button-wrapper">
              <div className="share-icon qr">
                <FaQrcode size={24} />
              </div>
              <span className="share-label">QR Code</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;