import React from 'react';
import FlexibleAudioPlayer from './FlexibleAudioPlayer';
import { getVoiceSampleUrl } from '../config/api';
import '../styles/Voices.css';
import '../styles/FlexibleAudioPlayerV2.css';

const Voices = ({ onClose }) => {
  const voiceSamples = [
    { voiceName: 'guy', title: 'Guy', category: 'Other' },
    { voiceName: 'jenny', title: 'Jenny', category: 'Other' },
    { voiceName: 'aria', title: 'Aria', category: 'Other' },
    { voiceName: 'davis', title: 'Davis', category: 'Other' },
    { voiceName: 'ryan', title: 'Ryan', category: 'Other' },
    { voiceName: 'nancy', title: 'Nancy', category: 'Other' },
    { voiceName: 'sara', title: 'Sara', category: 'Other' },
    { voiceName: 'tony', title: 'Tony', category: 'Other' },
    { voiceName: 'ana', title: 'Ana', category: 'Other' },
  ];

  return (
    <div className="voices-popup">
      <button onClick={onClose} className="close-button">X</button>
      <h2>Biglio Voices</h2>
      <div className="voices-content">
        {voiceSamples.map((sample, index) => (
          <FlexibleAudioPlayer
            key={index}
            audioUrl={getVoiceSampleUrl(sample.voiceName)}
            title={sample.title}
            category={sample.category}
          />
        ))}
      </div>
    </div>
  );
};

export default Voices;