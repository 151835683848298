import awsConfig from '../aws-exports';

const API_CONFIG = {
    suggestions: process.env.REACT_APP_SUGGESTIONS_URL || 'https://2uhtkxnckk.execute-api.us-east-1.amazonaws.com/prod/generate-suggestions',
    createAudio: process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/create-audio` : 'https://wq0vbglp42.execute-api.us-east-1.amazonaws.com/prod/create-audio',
    chat: process.env.REACT_APP_CHAT_URL || 'https://lo53bw79u8.execute-api.us-east-1.amazonaws.com/prod/chat',
    profile: process.env.REACT_APP_PROFILE_URL || 'https://g2422e46wd.execute-api.us-east-1.amazonaws.com/prod/profile',
    profileImageUpload: process.env.REACT_APP_PROFILE_IMAGE_URL || 'https://g2422e46wd.execute-api.us-east-1.amazonaws.com/prod/profile/image-upload',
    playlist: process.env.REACT_APP_PLAYLIST_URL || 'https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod',
    audioFiles: process.env.REACT_APP_AUDIO_FILES_URL || 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod',
    audioCategories: process.env.REACT_APP_AUDIO_CATEGORIES_URL || 'https://oovmxcx0n0.execute-api.us-east-1.amazonaws.com/v1',
    public: process.env.REACT_APP_PUBLIC_API_URL || 'https://utru71xp8h.execute-api.us-east-1.amazonaws.com/prod',
    private: process.env.REACT_APP_PRIVATE_API_URL || 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod',
    audioUpdate: process.env.REACT_APP_AUDIO_UPDATE_URL || 'https://n93z0uu1pe.execute-api.us-east-1.amazonaws.com/prod/audio-file',
    branding: process.env.REACT_APP_BRANDING_AUDIO_URL || 'https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/biglio_open_v1.mp3',
    shortUrl: process.env.REACT_APP_SHORT_URL || 'https://yqs259wu9i.execute-api.us-east-1.amazonaws.com/prod/shorten'
};

// S3 URLs
const S3_CONFIG = {
    defaultAvatar: process.env.REACT_APP_DEFAULT_AVATAR_URL || 'https://s3.us-west-2.amazonaws.com/biglio.com/static/media/default_avatar.png',
    audioStorage: process.env.REACT_APP_AUDIO_STORAGE_URL || 'https://storage-audio-file.s3.amazonaws.com/audio',
    voiceSamples: {
        andrew: process.env.REACT_APP_VOICE_ANDREW || 'voice-samples/voice-sample-andrew.mp3',
        ava: process.env.REACT_APP_VOICE_AVA || 'voice-samples/voice-sample-ava.mp3',
        ana: process.env.REACT_APP_VOICE_ANA || 'voice-samples/voice-sample-ana.mp3',
        ryan: process.env.REACT_APP_VOICE_RYAN || 'voice-samples/voice-sample-ryan.mp3'
    }
};

// API Endpoints
export const getSuggestionsEndpoint = () => API_CONFIG.suggestions;
export const getCreateAudioEndpoint = () => API_CONFIG.createAudio;
export const getChatEndpoint = () => API_CONFIG.chat;
export const getProfileEndpoint = () => `${API_CONFIG.profile}`;
export const getPlaylistEndpoint = () => API_CONFIG.playlist;
export const getAudioFilesEndpoint = () => API_CONFIG.audioFiles;
export const getAudioCategoriesEndpoint = () => API_CONFIG.audioCategories;
export const getShortUrlEndpoint = () => API_CONFIG.shortUrl;
export const getShortUrlLookupEndpoint = (shortCode) => `${API_CONFIG.shortUrl}/${shortCode}`;

// S3 URLs
export const getDefaultAvatarUrl = () => S3_CONFIG.defaultAvatar;
export const getAudioStorageUrl = () => S3_CONFIG.audioStorage;
export const getDemoAudioUrl = () => `${S3_CONFIG.audioStorage}/What-is-biglio.mp3`;

// Add getter for voice sample URLs
export const getVoiceSampleUrl = (voiceName) => {
    const filename = S3_CONFIG.voiceSamples[voiceName.toLowerCase()];
    return `${S3_CONFIG.audioStorage}/${filename}`;
};

// Add more endpoint getters as needed

const AWS_CONFIG = {
    userPoolId: process.env.REACT_APP_USER_POOL_ID || awsConfig.userPoolId,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || awsConfig.userPoolWebClientId
};

// Add getters for AWS config
export const getAwsConfig = () => ({
    UserPoolId: AWS_CONFIG.userPoolId,
    ClientId: AWS_CONFIG.userPoolWebClientId
});

export const getBrandingAudioUrl = () => 'https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/biglio_open_v1.mp3';
export const getPublicApiEndpoint = () => 'https://utru71xp8h.execute-api.us-east-1.amazonaws.com/prod';
export const getPrivateApiEndpoint = () => 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod';
export const getPlaylistApiEndpoint = () => 'https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod';
export const getAudioUpdateEndpoint = () => 'https://n93z0uu1pe.execute-api.us-east-1.amazonaws.com/prod/audio-file';

export const getProfileImageUploadEndpoint = () => API_CONFIG.profileImageUpload;