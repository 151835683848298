import { useState, useEffect } from 'react';

export const useViewportSize = () => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}; 