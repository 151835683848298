import React, { useState, useEffect, useRef } from 'react';
import { Globe, ExternalLink, Edit2, X, Check, Camera } from 'lucide-react';
import '../styles/ChannelHeader.css';
import { getProfileEndpoint, getDefaultAvatarUrl, getProfileImageUploadEndpoint, getPlaylistApiEndpoint, getPlaylistEndpoint } from '../config/api';
import ProfileImage from './ProfileImage';

const socialPlatforms = [
  { id: 'twitter', label: 'X' },
  { id: 'facebook', label: 'FB' },
  { id: 'instagram', label: 'IG' },
  { id: 'linkedin', label: 'IN' }
];

const validators = {
  url: (url) => {
    if (!url) return true;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  },
  bio: (text) => {
    if (!text) return true;
    const stripped = text.trim();
    return stripped.length >= 50 && stripped.length <= 400;
  },
  location: (text) => {
    if (!text) return true;
    return text.trim().length <= 100;
  }
};

const DEFAULT_AVATAR = getDefaultAvatarUrl();

const ChannelHeader = ({
  user,
  playlistsData,
  onPlaylistChange,
  playlistName,
  isOwner = false,
  onError
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  
  const [profileData, setProfileData] = useState({
    bio: '',
    location: '',
    website: '',
    socials: {
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: ''
    }
  });

  const [editState, setEditState] = useState(profileData);

  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const fileInputRef = useRef(null);

  const [expanded, setExpanded] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [playlists, setPlaylists] = useState([]);

  const truncateText = (text, lines) => {
    if (!text) return '';

    const words = text.split(' ');
    // Approximate words per line (adjust this based on your layout)
    const wordsPerLine = 20;
    const truncateAt = lines * wordsPerLine;

    if (words.length <= truncateAt) return text;

    return words.slice(0, truncateAt).join(' ') + '...';
  };

  const renderBio = () => {
    if (!profileData.bio) return null;

    const bioLength = profileData.bio.split(' ').length;
    const truncatedLength = truncateText(profileData.bio, 2).split(' ').length;

    return (
      <div>
        <div className={`bio-content ${expanded ? 'expanded' : ''}`}>
          {expanded ? profileData.bio : truncateText(profileData.bio, 2)}
        </div>
        <div className="bio-button-container">
          {!expanded && bioLength > truncatedLength && (
            <button className="bio-toggle-button" onClick={() => setExpanded(true)}>
              Read More
            </button>
          )}
          {expanded && (
            <button className="bio-toggle-button" onClick={() => setExpanded(false)}>
              Show Less
            </button>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const userId = user?.username;
      if (!userId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${getProfileEndpoint()}?userId=${userId}`);

        let normalizedData;
        
        if (!response.ok) {
          // If user doesn't exist in DB yet, use default empty profile
          normalizedData = {
            bio: '',
            location: '',
            website: '',
            profileImage: null,
            socials: {
              twitter: '',
              facebook: '',
              instagram: '',
              linkedin: ''
            }
          };
        } else {
          const data = await response.json();
          // Normalize the data whether it exists or not
          normalizedData = {
            bio: data.bio || '',
            location: data.location || '',
            website: data.website || '',
            profileImage: data.profileImage || null,
            socials: {
              twitter: data.socials?.twitter || '',
              facebook: data.socials?.facebook || '',
              instagram: data.socials?.instagram || '',
              linkedin: data.socials?.linkedin || ''
            }
          };
        }

        setProfileData(normalizedData);
        setEditState(normalizedData);
      } catch (err) {
        console.error('Error fetching profile:', err);
        // Even on error, set default empty profile
        const defaultProfile = {
          bio: '',
          location: '',
          website: '',
          profileImage: null,
          socials: {
            twitter: '',
            facebook: '',
            instagram: '',
            linkedin: ''
          }
        };
        setProfileData(defaultProfile);
        setEditState(defaultProfile);
        setError('Failed to load profile data');
        onError?.(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.username) {
      fetchProfile();
    }
  }, [user?.username, onError]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      if (!user?.userid) return;
      
      try {
        const response = await fetch(`${getPlaylistEndpoint()}/playlists?userid=${user.userid}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch playlists: ${response.status}`);
        }
        const data = await response.json();
        setPlaylists(data.playlists || []);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setPlaylists([]);
      }
    };

    fetchPlaylists();
  }, [user]);

  const validateForm = () => {
    const errors = {};

    if (!validators.bio(editState.bio)) {
      errors.bio = 'Bio must be between 50 and 400 characters';
    }
    if (!validators.location(editState.location)) {
      errors.location = 'Location cannot exceed 100 characters';
    }
    if (editState.website && !validators.url(editState.website)) {
      errors.website = 'Invalid website URL';
    }

    Object.entries(editState.socials).forEach(([platform, url]) => {
      if (url && !validators.url(url)) {
        errors[platform] = `Invalid ${platform} URL`;
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;
  
    const userId = user?.username;
    if (!userId) {
      console.error('User ID is missing. Cannot save profile.');
      setError('User ID is missing. Please log in again.');
      return;
    }
  
    try {
      console.log('Saving profile for userId:', userId);
      const authToken = localStorage.getItem('authToken');
      const response = await fetch(
        `${getProfileEndpoint()}?userId=${userId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({
            ...editState
          })
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to save profile: ${errorData.error || response.statusText}`);
      }
  
      setProfileData(editState);
      setIsEditing(false);
      setValidationErrors({});
    } catch (err) {
      console.error('Error saving profile:', err.message);
      setError('Failed to save profile data');
      onError?.(err);
    }
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validTypes.includes(file.type)) {
      throw new Error("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
    }
    if (file.size > 2 * 1024 * 1024) { // 2MB
      throw new Error("File size too large. Please upload an image smaller than 2MB.");
    }
    return true;
  };

  const handleImageUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      validateFile(file);
      setIsUploadingImage(true);

      const userId = user?.username;
      if (!userId) {
        throw new Error('You must be logged in to upload an image');
      }

      console.log('Uploading image:', {
        fileName: file.name,
        fileType: file.type,
        fileSize: `${(file.size / 1024 / 1024).toFixed(2)}MB`
      });

      const endpoint = getProfileImageUploadEndpoint();
      console.log('Making request to:', {
        endpoint,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          contentType: file.type,
          userId
        }
      });

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contentType: file.type,
          userId
        })
      });

      // Detailed error logging
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Response status:', response.status);
        console.error('Response headers:', Object.fromEntries(response.headers.entries()));
        console.error('Error data:', errorData);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || response.statusText}`);
      }

      const data = await response.json();
      console.log('Got pre-signed URL response:', {
        status: response.status,
        uploadUrl: data.uploadUrl ? 'Present' : 'Missing',
        imageUrl: data.imageUrl ? 'Present' : 'Missing'
      });

      if (!data.uploadUrl) {
        throw new Error('No upload URL received from server');
      }

      // Step 2: Upload to S3
      console.log('Uploading to S3...');
      const uploadResponse = await fetch(data.uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload image to S3');
      }

      console.log('Upload successful, updating profile with new image URL');
      
      // Step 3: Update local state with new image URL
      setProfileData(prev => ({
        ...prev,
        profileImage: data.imageUrl
      }));

    } catch (error) {
      console.error('Error uploading image:', error);
      alert(error.message);
    } finally {
      setIsUploadingImage(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="channel-header">
      <div className="channel-header-content">
        <div className="banner-section">
          <div className="profile-image-container">
            <ProfileImage 
              imageUrl={profileData?.profileImage}
              defaultImage={DEFAULT_AVATAR}
            />
            {isOwner && (
              <>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  accept="image/jpeg,image/png,image/gif"
                  style={{ display: 'none' }}
                />
                <div 
                  className="profile-image-edit"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <Camera size={24} />
                </div>
              </>
            )}
            {isUploadingImage && (
              <div className="profile-image-loading">
                <div className="spinner" />
              </div>
            )}
          </div>
          
          <div className="banner-content">
            <h1 className="banner-username">
              {user?.channelName || user?.username || "Channel"}
            </h1>
            
            <div className="stats">
              <div className="stat-item">
                <span className="stat-number">{playlists?.length || 0}</span>
                <span className="stat-label">Playlists</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">0</span>
                <span className="stat-label">Followers</span>
              </div>
            </div>

            {isOwner && (
              <div className="customize-controls">
                {isEditing ? (
                  <>
                    <button
                      onClick={handleSave}
                      className="customize-button save"
                      title="Save changes"
                    >
                      <Check className="icon" />
                    </button>
                    <button
                      onClick={() => {
                        setIsEditing(false);
                        setEditState(profileData);
                        setValidationErrors({});
                      }}
                      className="customize-button cancel"
                      title="Cancel"
                    >
                      <X className="icon" />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      setIsEditing(true);
                      setEditState(profileData);
                    }}
                    className="customize-button"
                    title="Edit profile"
                  >
                    <Edit2 className="icon" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="bio-section">
          {isEditing ? (
            <div className="edit-section">
              <div>
                <textarea
                  value={editState.bio}
                  onChange={(e) => setEditState(prev => ({ ...prev, bio: e.target.value }))}
                  className="bio-input"
                  rows={4}
                  maxLength={400}
                  placeholder="Tell others about yourself..."
                />
                <div className="character-count">
                  {editState.bio.length}/400 characters (minimum 50)
                </div>
              </div>
              
              <input
                type="text"
                value={editState.location}
                onChange={(e) => setEditState(prev => ({ ...prev, location: e.target.value }))}
                className="location-input"
                placeholder="Location (optional)"
              />
            </div>
          ) : (
            renderBio()
          )}
        </div>

        {!isEditing && profileData.location && (
          <div className="location-display">
            <span>{profileData.location}</span>
          </div>
        )}

        <div className="social-links">
          <div className="social-links-container">
            {Object.entries(profileData.socials || {}).map(([platform, url]) => (
              url && (
                <a
                  key={platform}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  {socialPlatforms.find(p => p.id === platform)?.label}
                  <ExternalLink className="icon" />
                </a>
              )
            ))}
            {profileData.website && (
              <a
                href={profileData.website}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                Website
                <ExternalLink className="icon" />
              </a>
            )}
          </div>
          
          {isEditing && (
            <div className="social-edit-section">
              {socialPlatforms.map(platform => (
                <input
                  key={platform.id}
                  type="text"
                  value={editState.socials[platform.id] || ''}
                  onChange={(e) => setEditState(prev => ({
                    ...prev,
                    socials: {
                      ...prev.socials,
                      [platform.id]: e.target.value
                    }
                  }))}
                  className="social-input"
                  placeholder={`${platform.label} profile URL`}
                />
              ))}
              
              <input
                type="text"
                value={editState.website || ''}
                onChange={(e) => setEditState(prev => ({ ...prev, website: e.target.value }))}
                className="social-input"
                placeholder="Website URL"
              />
            </div>
          )}
        </div>

        <div className="playlist-section">
          <select
            value={playlistName}
            onChange={onPlaylistChange}
            className="playlist-select"
          >
            <option value="">All Playlists</option>
            {playlists?.map((playlist, index) => (
              <option key={index} value={playlist}>
                {playlist}
              </option>
            ))}
          </select>
          {isOwner && (
            <div className="customize-controls playlist">
              {isEditing ? (
                <>
                  <button
                    onClick={handleSave}
                    className="customize-button save"
                    title="Save changes"
                  >
                    <Check className="icon" />
                  </button>
                  <button
                    onClick={() => {
                      setIsEditing(false);
                      setEditState(profileData);
                      setValidationErrors({});
                    }}
                    className="customize-button cancel"
                    title="Cancel"
                  >
                    <X className="icon" />
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setIsEditing(true);
                    setEditState(profileData);
                  }}
                  className="customize-button"
                  title="Edit playlists"
                >
                  <Edit2 className="icon" />
                </button>
              )}
            </div>
          )}
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        {Object.keys(validationErrors).length > 0 && (
          <div className="error-message">
            Please fix the following errors:
            <ul className="list-disc list-inside">
              {Object.entries(validationErrors).map(([field, error]) => (
                <li key={field}>{error}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default ChannelHeader;