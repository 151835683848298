import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { UserCircle, QrCode } from 'lucide-react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';
import XIcon from './site_images/x-icon.svg.webp';
import SMSIcon from './site_images/sms-icon.png';
import PlaceholderImage from './site_images/player_placeholder.png';
import '../styles/FlexibleAudioPlayerV2.css';
import QRCodeModal from './QRCodeModal';
import ShareModal from './ShareModal';
import { getBrandingAudioUrl } from '../config/api';

const AUDIO_PLAY_EVENT = 'audioPlayEvent';

const FlexibleAudioPlayer = ({ tracks = [] }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [isBrandingPlayed, setIsBrandingPlayed] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const audioRef = useRef(null);
  const brandingAudioRef = useRef(null);
  const playerIdRef = useRef(`player-${Math.random()}`);

  const currentTrack = tracks[0]; // We only need the first track for shared audio

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  }, []);

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  }, []);

  const handleError = useCallback((e) => {
    console.error('Audio error:', e);
    setError('An error occurred while playing the audio. Please try again.');
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const handleOtherPlayerStart = (event) => {
      if (event.detail.playerId !== playerIdRef.current && isPlaying) {
        audioRef.current?.pause();
        brandingAudioRef.current?.pause();
        setIsPlaying(false);
      }
    };

    window.addEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
    return () => window.removeEventListener(AUDIO_PLAY_EVENT, handleOtherPlayerStart);
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    const brandingAudio = brandingAudioRef.current;
    if (!audio || !brandingAudio) return;

    const handleBrandingEnd = () => {
      setIsBrandingPlayed(true);
      audio.play().then(() => {
        setIsPlaying(true);
        window.dispatchEvent(new CustomEvent(AUDIO_PLAY_EVENT, {
          detail: { playerId: playerIdRef.current }
        }));
      }).catch(error => {
        console.error('Main audio playback failed:', error);
        setError('Playback failed. Please try again.');
        setIsPlaying(false);
      });
    };

    brandingAudio.addEventListener('ended', handleBrandingEnd);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('error', handleError);
    brandingAudio.addEventListener('error', handleError);

    return () => {
      brandingAudio.removeEventListener('ended', handleBrandingEnd);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('error', handleError);
      brandingAudio.removeEventListener('error', handleError);
    };
  }, [handleLoadedMetadata, handleTimeUpdate, handleError]);

  useEffect(() => {
    if (currentTrack?.audioUrl && audioRef.current) {
      audioRef.current.src = currentTrack.audioUrl;
      audioRef.current.load();
    }
  }, [currentTrack]);

  const handlePlayPause = useCallback((e) => {
    if (e.type === 'click' && e.detail === 0) return;
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      brandingAudioRef.current?.pause();
      setIsPlaying(false);
    } else {
      window.dispatchEvent(new CustomEvent(AUDIO_PLAY_EVENT, {
        detail: { playerId: playerIdRef.current }
      }));

      if (!isBrandingPlayed && brandingAudioRef.current) {
        brandingAudioRef.current.play().catch(handleError);
      } else {
        audioRef.current.play().then(() => {
          setIsPlaying(true);
        }).catch(handleError);
      }
    }
  }, [isPlaying, isBrandingPlayed, handleError]);

  const handleSeek = useCallback((e) => {
    if (!audioRef.current) return;
    
    if (e.type.includes('touch')) {
      e.preventDefault();
    }
    
    const rect = e.target.getBoundingClientRect();
    const clientX = e.type.includes('touch') 
      ? e.touches[0].clientX 
      : e.clientX;
    
    const offsetX = Math.min(Math.max(0, clientX - rect.left), rect.width);
    const newTime = (offsetX / rect.width) * duration;
    
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  }, [duration]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const getShareUrl = () => {
    if (currentTrack.shortCode) {
      const path = `/s/${currentTrack.shortCode}`;
      return window.location.hostname === 'localhost' ? `https://biglio.com${path}` : `${window.location.origin}${path}`;
    }
    return `${window.location.origin}/audio-player?id=${currentTrack.id}&audioUrl=${encodeURIComponent(currentTrack.audioUrl)}&topic=${encodeURIComponent(currentTrack.topic)}&username=${encodeURIComponent(currentTrack.username)}`;
  };

  if (!currentTrack?.audioUrl) {
    return <div className="no-tracks">Audio not available</div>;
  }

  const shareUrl = getShareUrl();
  
  return (
    <>
      <Helmet>
        <meta property="og:title" content={currentTrack.topic} />
        <meta property="og:description" content={`Listen to ${currentTrack.username}'s audio`} />
        <meta property="og:image" content={PlaceholderImage} />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:type" content="music.song" />
        <meta property="og:audio" content={currentTrack.audioUrl} />
        <meta property="og:audio:type" content="audio/mpeg" />
        
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={currentTrack.topic} />
        <meta name="twitter:description" content={`Listen to ${currentTrack.username}'s audio`} />
        <meta name="twitter:image" content={PlaceholderImage} />
      </Helmet>

      <div className="audio-player" role="region" aria-label="Audio player">
        <img src={PlaceholderImage} alt="" className="audio-placeholder" />
        <div className="audio-details">
          <h3 className="track-title">{currentTrack.topic}</h3>
          <div className="channel-link-container">
            <Link 
              to={`/channel/${currentTrack.username}`} 
              className="channel-link-inner"
            >
              <UserCircle size={18} style={{ opacity: 0.7 }} />
              <span className="username">{currentTrack.username}</span>
            </Link>
          </div>
          <audio ref={brandingAudioRef} src={getBrandingAudioUrl()} preload="auto" />
          <audio ref={audioRef} preload="auto" />
          {error && <div className="error-message" role="alert">{error}</div>}
          <div className="controls">
            <button
              onClick={handlePlayPause}
              className="custom-play-button" 
              aria-label={isPlaying ? 'Pause' : 'Play'}
            >
              {isPlaying ? 'Pause' : 'Play'}
            </button>
          </div>
          <div className="seek-bar-container">
            <div className="time-display">
              <span className="current-time">{formatTime(currentTime)}</span>
              <span className="total-time">{formatTime(duration)}</span>
            </div>
            <div 
              className="progress-track" 
              onClick={handleSeek}
              onTouchStart={handleSeek}
              onTouchMove={handleSeek}
              onTouchEnd={(e) => e.preventDefault()}
              role="slider"
              aria-label="Seek audio"
              aria-valuemin="0"
              aria-valuemax={duration}
              aria-valuenow={currentTime}
            >
              <div 
                className="progress-bar" 
                style={{ width: `${(currentTime / duration) * 100}%` }}
              ></div>
              <div 
                className="seek-thumb" 
                style={{ left: `${(currentTime / duration) * 100}%` }}
              ></div>
            </div>
          </div>

          <div className="button-container">
            <button 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowShareModal(true);
              }}
              className="share-toggle-button"
            >
              Share
            </button>
          </div>

          <ShareModal
            isOpen={showShareModal}
            onClose={() => setShowShareModal(false)}
            shareUrl={getShareUrl()}
            topic={currentTrack.topic}
            username={currentTrack.username}
          />

          <button 
            onClick={() => setShowQRCode(true)}
            className="qr-button"
            aria-label="Show QR Code"
          >
            <QrCode size={32} color="white" />
          </button>
        </div>
      </div>

      {showQRCode && (
        <QRCodeModal
          url={shareUrl}
          onClose={() => setShowQRCode(false)}
        />
      )}
    </>
  );
};

export default FlexibleAudioPlayer;