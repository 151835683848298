import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Printer } from 'lucide-react';

const PrintableQRCode = ({ url, topic, username }) => {
  const printQRCode = () => {
    const printWindow = window.open('', '_blank');
    
    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Print QR Code - ${topic}</title>
          <style>
            body {
              margin: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              min-height: 100vh;
            }
            .print-container {
              text-align: center;
            }
            .qr-title {
              font-size: 16px;
              margin-bottom: 12px;
              font-family: sans-serif;
            }
            .qr-subtitle {
              font-size: 14px;
              color: #666;
              margin-bottom: 20px;
              font-family: sans-serif;
            }
          </style>
        </head>
        <body>
          <div class="print-container">
            <div class="qr-title">${topic}</div>
            <div class="qr-subtitle">by ${username}</div>
            ${document.querySelector('.qr-code-wrapper').innerHTML}
          </div>
          <script>
            window.onload = () => {
              window.print();
              window.close();
            }
          </script>
        </body>
      </html>
    `;
    
    printWindow.document.write(printContent);
    printWindow.document.close();
  };

  return (
    <button
      onClick={printQRCode}
      className="qr-print-button flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-700"
    >
      <Printer size={20} />
      Print QR Code
    </button>
  );
};

export default PrintableQRCode; 