import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import FlexibleAudioPlayer from './FlexibleAudioPlayer';
import { getShortUrlLookupEndpoint } from '../config/api';
import '../styles/AudioPlayerPageV2.css';

const AudioPlayerPage = ({ openModal }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const { shortCode } = useParams();
  const [audioData, setAudioData] = useState(null);
  
  useEffect(() => {
    const loadAudioData = async () => {
      // If we have a shortCode from the URL path, fetch the data from BiglioUrlMapping
      if (shortCode) {
        try {
          const response = await fetch(getShortUrlLookupEndpoint(shortCode), {
            headers: {
              'Accept': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Failed to fetch audio data');
          }
          const data = await response.json();
          setAudioData({
            audioUrl: data.audioUrl,
            topic: data.topic,
            username: data.username,
            shortCode: shortCode
          });
          return;
        } catch (error) {
          console.error('Error fetching audio data:', error);
          return;
        }
      }

      const id = searchParams.get('id');
      
      // If we have direct parameters, use them
      if (!id) {
        setAudioData({
          audioUrl: searchParams.get('audioUrl'),
          topic: searchParams.get('topic'),
          username: searchParams.get('username'),
          shortCode: searchParams.get('shortCode')
        });
        return;
      }

      try {
        // If we have an ID, fetch the audio metadata from DynamoDB
        const response = await fetch(`https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod/audio-file/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch audio data');
        }
        const data = await response.json();
        setAudioData({
          audioUrl: data.audioUrl,
          topic: data.topic,
          username: data.username,
          shortCode: data.shortCode,
          id: id
        });
      } catch (error) {
        console.error('Error fetching audio data:', error);
      }
    };

    loadAudioData();
  }, [searchParams, shortCode]);

  if (!audioData?.audioUrl) {
    return <div className="not-found">Audio not found</div>;
  }

  return (
    <div className="audio-player-page">
      <div className="player-container">
        <FlexibleAudioPlayer tracks={[audioData]} />
        <div className="signup-banner">
          {isAuthenticated ? (
            <>
              <h3>Ready to Create?</h3>
              <p>Head over to the Create page to make your own audio content!</p>
              <Link to="/create" className="signup-button">
                Start Creating
              </Link>
            </>
          ) : (
            <>
              <h3>Create Your Own Audio Content</h3>
              <p>Join Biglio to start creating professional audio in minutes!</p>
              <button onClick={() => openModal()} className="signup-button">
                SIGN IN
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerPage;