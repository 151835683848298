import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import SignInModal from './components/SignInModal';
import CreatePage from './components/CreatePage';
import AudioPlayerPage from './components/AudioPlayerPage';
import { AuthProvider } from './context/AuthContext';
import { AudioProvider } from './context/AudioContext';
import Voices from './components/Voices';
import ChannelPage from './components/ChannelPage';
import ChatContainer from './biglio-chat/components/ChatContainer';
import { Toaster } from 'react-hot-toast';
import './App.css';
import './index.css';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialView, setInitialView] = useState('signin');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <AuthProvider>
      <AudioProvider>
        <div className="app-container bg-gray-900 min-h-screen">
          <Navbar onSignInClick={openModal} onSignUpClick={openModal} />
          <main className="main-content text-white">
            <Routes>
              <Route path="/" element={<HomePage openModal={openModal} />} />
              <Route path="/create" element={<CreatePage />} />
              <Route path="/channel/:username" element={<ChannelPage />} />
              <Route path="/voices" element={<Voices />} />
              <Route path="/biglio-chat/*" element={<ChatContainer />} />
              <Route path="/audio-player" element={<AudioPlayerPage openModal={() => { setIsModalOpen(true); setInitialView('signup'); }} />} />
              <Route path="/s/:shortCode" element={<AudioPlayerPage openModal={() => { setIsModalOpen(true); setInitialView('signup'); }} />} />
            </Routes>
          </main>
          <SignInModal isOpen={isModalOpen} onClose={closeModal} initialView={initialView} />
        </div>
      </AudioProvider>
      <Toaster 
        position="bottom-center"
        toastOptions={{
          style: {
            background: '#333',
            color: '#fff',
          },
        }}
      />
    </AuthProvider>
  );
};

export default App;