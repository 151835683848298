import React from 'react';

export const SpicyOverlay = ({ message, onClose }) => {
  return (
    <div className="spicy-overlay">
      <div className="spicy-content">
        <h3>Oops! 🌶️</h3>
        <p>{message}</p>
        <button onClick={onClose}>Try Again</button>
      </div>
    </div>
  );
}; 