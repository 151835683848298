import React, { createContext, useState, useEffect, useCallback } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import awsConfig from '../aws-exports';

const AuthContext = createContext();

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.userPoolWebClientId,
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastRefresh, setLastRefresh] = useState(0);

  const isTokenValid = useCallback((tokenExpiration) => {
    const currentTime = Date.now();
    // Add 5 minute buffer to ensure token doesn't expire during use
    const bufferTime = 5 * 60 * 1000; 
    return tokenExpiration > (currentTime + bufferTime);
  }, []);

  const getFormattedToken = useCallback(() => {
    if (!user?.idToken) {
        console.log('Token check failed:', {
            hasUser: !!user,
            hasIdToken: !!user?.idToken
        });
        return null;
    }

    // ISSUE: Currently returning accessToken but naming suggests idToken
    const token = user.accessToken;  // This might be an object, not a string

    if (!token) {
        console.log('Invalid token format');
        return null;
    }

    return token;
  }, [user]);

  const updateAuthState = useCallback((cognitoUser) => {
    if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
            if (!err && session && session.isValid()) {
                const idToken = session.getIdToken().getJwtToken();
                
                setIsAuthenticated(true);
                setUser({
                    userid: session.getIdToken().payload.sub,
                    username: cognitoUser.getUsername(),
                    idToken: idToken, // Store the JWT string directly
                    accessToken: session.getAccessToken(),
                    refreshToken: session.getRefreshToken(),
                    tokenExpiration: session.getIdToken().getExpiration() * 1000,
                });
            } else {
                console.error('Session invalid or error:', err);
                setIsAuthenticated(false);
                setUser(null);
            }
        });
    }
  }, []);

  const refreshSession = useCallback(async (force = false) => {
    const currentTime = Date.now();
    
    if (!force && user?.tokenExpiration && user.tokenExpiration > currentTime) {
        console.log('Using existing valid token');
        return Promise.resolve(user);
    }

    return new Promise((resolve) => {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            console.log('No user found - normal for non-authenticated state');
            setIsAuthenticated(false);
            setUser(null);
            setIsLoading(false);
            return resolve(null);
        }

        cognitoUser.getSession((err, session) => {
            if (err || !session.isValid()) {
                console.log('Session invalid or error');
                setIsAuthenticated(false);
                setUser(null);
                setIsLoading(false);
                resolve(null);
                return;
            }

            const newUser = {
                userid: session.getIdToken().payload.sub,
                username: cognitoUser.getUsername(),
                idToken: session.getIdToken().getJwtToken(),
                accessToken: session.getAccessToken().getJwtToken(),
                refreshToken: session.getRefreshToken(),
                tokenExpiration: session.getAccessToken().getExpiration() * 1000,
            };

            setIsAuthenticated(true);
            setUser(newUser);
            setLastRefresh(currentTime);
            resolve(newUser);
        });
    });
}, [user, lastRefresh]);

useEffect(() => {
    const initializeAuth = async () => {
        try {
            const result = await refreshSession();
            if (!result) {
                console.log('No authenticated user found - normal state');
            }
        } catch (error) {
            console.error('Unexpected error during auth initialization:', error);
        } finally {
            setIsLoading(false);
        }
    };

    initializeAuth();
}, [refreshSession]);

  const signOut = useCallback(() => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
    setIsAuthenticated(false);
    setUser(null);
  }, []);

  const contextValue = {
    isAuthenticated,
    user,
    isLoading,
    setIsAuthenticated,
    refreshSession,
    signOut,
    updateAuthState,
    getFormattedToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };