import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Voices from './Voices';
import SignInModal from './SignInModal';
import ReCAPTCHA from 'react-google-recaptcha';
import '../styles/CreatePageV11.css';
import { checkContent } from '../utils/moderation';
import { SpicyOverlay } from './SpicyOverlay';
import lottie from 'lottie-web';
import animationData from './CreateAnimation.json';
import { 
  getCreateAudioEndpoint, 
  getSuggestionsEndpoint, 
  getPlaylistEndpoint,
  getVoiceSampleUrl 
} from '../config/api';
import { getRecaptchaSiteKey } from '../config/security';
import { Popover } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ContentTypeTooltip from './ContentTypeTooltip';
import '../styles/ContentTypeTooltip.css';
import { transformRequestData, reverseTransform } from '../utils/requestUtils';

// Your existing categories array
// [Previous imports remain exactly the same]

const categories = [
  'Technology', 'Health', 'Science', 'Business', 'Sports', 'Travel', 'History', 'Children\'s Story',
  'Short Story', 'Time Machine', 'Recipes', 'Biography', 'Motivation', 'Other', 'Custom Text'
];

const getTimeMachineInstructions = () => {
  return {
    title: "Time Machine Format",
    examples: [
      "Speak as Albert Einstein discussing artificial intelligence",
      "Speak as Leonardo da Vinci examining modern technology",
      "Speak as Abraham Lincoln addressing current political divisions",
      "Speak as Marie Curie discussing women in modern science"
    ],
    format: "Speak as [Historical Figure] discussing/examining/addressing [Topic]. Include references to their own time period and experiences."
  };
};

const VoicePreview = ({ voice, onSelect }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio());

  const handlePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    } else {
      audio.src = getVoiceSampleUrl(voice);
      audio.play().catch(error => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  useEffect(() => {
    const handleEnded = () => setIsPlaying(false);
    audio.addEventListener('ended', handleEnded);
    return () => audio.removeEventListener('ended', handleEnded);
  }, [audio]);

  return (
    <div className="button-group">
      <button 
        onClick={handlePreview}
        className="preview-button"
        type="button"
      >
        {isPlaying ? '⏹️  Stop   ' : '▶️ Preview'}
      </button>
      <button 
        onClick={() => onSelect(voice)}
        className="choose-button"
        type="button"
      >
        Choose
      </button>
    </div>
  );
};

const CHARS_PER_MINUTE = 750; // Approximately 150 words per minute, 5 chars per word
const MAX_MINUTES = 5;
const MAX_CHARS = CHARS_PER_MINUTE * MAX_MINUTES; // 3,750 characters

function CreatePage() {
  const navigate = useNavigate();
  const { isAuthenticated, user, isLoading, refreshSession } = useContext(AuthContext);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const recaptchaRef = useRef(null);
  const [isStreak, setIsStreak] = useState(false);

  const toggleStreak = () => {
    setIsStreak(!isStreak);
  };

  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState('');
  const [specificFocus, setSpecificFocus] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [error, setError] = useState(null);
  const [contentReady, setContentReady] = useState(false);
  const [showVoices, setShowVoices] = useState(false);
  const [progress, setProgress] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [autoSelectedVoice, setAutoSelectedVoice] = useState(false);
  const [showSpicyOverlay, setShowSpicyOverlay] = useState(false);
  const [spicyMessage, setSpicyMessage] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [timeEstimate, setTimeEstimate] = useState('0');
  const [isOverLimit, setIsOverLimit] = useState(false);
  const CHAR_LIMIT = 3750; // 5-minute limit
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState('');
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState('');
  const [showTooltip, setShowTooltip] = useState(null);

  const availableVoices = [
    { id: 'Andrew', name: 'Andrew', description: 'Adult Male', avatar: '👨' },
    { id: 'Ava', name: 'Ava', description: 'Adult Female', avatar: '👩' },
    { id: 'Ryan', name: 'Ryan', description: 'British Male', avatar: '👨' },
    { id: 'Ana', name: 'Ana', description: 'Child Female', avatar: '👧' }
  ];

  const suggestVoice = (selectedCategory) => {
    switch(selectedCategory) {
      case 'Children\'s Story':
        setSelectedVoice('Ana');
        break;
      case 'Business':
      case 'Technology':
        setSelectedVoice('Andrew');
        break;
      default:
        setSelectedVoice('Ava');
    }
    setAutoSelectedVoice(true);
  };

  useEffect(() => {
    const refreshUserSession = async () => {
      try {
        await refreshSession();
      } catch (err) {
        console.error('Error refreshing session:', err);
      }
    };

    refreshUserSession();
  }, [refreshSession]);

  const handleCategoryChange = (e) => {
    if (!isAuthenticated) return;

    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    suggestVoice(selectedCategory);
  };

  const handleGetSuggestions = async () => {
    if (!topic) return;
    
    try {
      setLoadingSuggestions(true);
      const response = await fetch(getSuggestionsEndpoint(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          topic,
          category,
          specificFocus
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSuggestions(data.suggestions || []);
    } catch (error) {
      console.error('Error getting suggestions:', error);
      setSuggestions([]);
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleUseSuggestion = (suggestion) => {
    setSelectedSuggestions((prevSelected) => {
      if (prevSelected.includes(suggestion)) {
        const newSelections = prevSelected.filter((s) => s !== suggestion);
        setSpecificFocus((prevFocus) => {
          const updatedFocus = prevFocus.replace(suggestion, '').trim();
          return updatedFocus;
        });
        return newSelections;
      } else {
        const newSelections = [...prevSelected, suggestion];
        setSpecificFocus((prevFocus) => {
          const prefix = prevFocus ? prevFocus + ' ' : '';
          return prefix + suggestion;
        });
        return newSelections;
      }
    });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    console.log('Generate button clicked');

    if (!isAuthenticated) {
      console.log('User not authenticated');
      setError('Please sign in to create content');
      return;
    }

    const contentCheck = checkContent(topic, specificFocus);
    if (!contentCheck.isValid) {
        setSpicyMessage(contentCheck.message);
        setShowSpicyOverlay(true);
        return;
    }  

    try {
        const token = await recaptchaRef.current.execute();
        setLoadingGenerate(true);
        setError(null);

        const userSub = user.userid;
        if (!userSub) {
            throw new Error('User ID not available. Please log in again.');
        }
        
        const response = await fetch(getCreateAudioEndpoint(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                topic,
                category,
                specificFocus,
                selectedVoice,
                intent: selectedIntent,
                suggestions: selectedSuggestions,
                userid: userSub,
                username: user.username,
                recaptchaToken: token,
                isStreak,
                playlistName: selectedPlaylist
            }),
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || `Server error: ${response.status}`);
        }

        const data = await response.json();
        setContentReady(true);
    } catch (err) {
        console.error('Error generating audio:', err);
        setError(`An error occurred while generating audio: ${err.message}`);
        recaptchaRef.current.reset();
    } finally {
        setLoadingGenerate(false);
    }
  };

  const handleNavigateToProfile = () => {
    navigate(`/channel/${user.username}`);
  };

  const calculateTimeEstimate = (text) => {
    const wordCount = text.length / 5;
    const minutes = Math.ceil(wordCount / 150);
    return minutes.toString();
  };

  const handleSpecificFocusChange = (e) => {
    const text = e.target.value;
    const chars = text.length;
    
    setCharCount(chars);
    setIsOverLimit(chars > MAX_CHARS);
    
    // Calculate time estimate
    const minutes = Math.ceil(chars / CHARS_PER_MINUTE);
    setTimeEstimate(minutes.toString());

    // Only update text if within limit for Custom Text
    if (category !== 'Custom Text' || chars <= MAX_CHARS) {
      setSpecificFocus(text);
    }
  };

  useEffect(() => {
    const fetchPlaylists = async () => {
      if (!isAuthenticated || !user?.username) return;
      
      try {
        const response = await fetch(`${getPlaylistEndpoint()}/playlists?userid=${user.userid}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch playlists: ${response.status}`);
        }
        const data = await response.json();
        setPlaylists(data.playlists || []);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setPlaylists([]);
      }
    };

    fetchPlaylists();
  }, [isAuthenticated, user]);

  const animationContainer = useRef(null);

  useEffect(() => {
    let animation = null;
    
    if (loadingGenerate && !contentReady && animationContainer.current) {
      animation = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      });
    }

    return () => {
      if (animation) {
        animation.destroy();
      }
    };
  }, [loadingGenerate, contentReady]);

  const getCategorySpecificContent = (category) => {
    switch (category) {
      case 'Recipes':
        return {
          label: 'What would you like a recipe for?',
          details: 'Tell us about any specific ingredients, dietary needs, or cooking methods you\'d like us to consider.',
        };
      case 'Technology':
        return {
          label: 'What technology topic would you like to cover?', 
          details: 'Help us understand which aspects you want to explore - from core concepts to practical applications.',
        };
      case 'Time Machine':
        return {
          label: 'Time Machine Format',
          details: 'Which aspects of this time period interest you most? We\'ll create the perfect historical journey.',
        };
      case 'Business':
        return {
          label: 'What is the subject of your business content?',
          details: 'What specific insights or strategies would be most valuable to explore?',
        };
      case 'Children\'s Story':
        return {
          label: 'What is the title of your children\'s story?',
          details: 'Tell us about the characters and magical elements you\'d like us to weave into your tale!',
        };
      case 'Health':
        return {
          label: 'What health topic would you like to address?',
          details: 'Which aspects of this health topic should we focus on to provide the most valuable insights?',
        };
      case 'Science':
        return {
          label: 'What scientific subject would you like to delve into?',
          details: 'What fascinating aspects of this scientific topic should we explore together?',
        };
      case 'Sports':
        return {
          label: 'What sports topic or event would you like to discuss?',
          details: 'What key aspects of this sport or event should we highlight?',
        };
      case 'Travel':
        return {
          label: 'What travel destination or topic would you like to explore?',
          details: 'Help us understand which aspects of this destination you\'d most like to discover.',
        };
      case 'Short Story':
        return {
          label: 'What is the title or theme of your short story?',
          details: 'Share the mood, themes, or plot elements you\'d like us to focus on.',
        };
      case 'Biography': 
        return {
          label: 'Whose biography would you like to explore?',
          details: 'Which aspects of their life journey should we highlight?',
        };
      case 'Motivation':
        return {
          label: 'What motivational topic or theme would you like to focus on?',
          details: 'What transformative messages or insights should we emphasize?',
        };
      case 'Custom Text':
        return {
          label: 'Please provide the custom text for your content.',
          details: 'Just paste your text here and we\'ll transform it into engaging audio!',
        };
      default:
        return {
          label: 'Let\'s give this a title related to the content you want to create.',
          details: 'What key elements or aspects should we focus on to make this content shine?',
        };
    }
   };

  return (
    <div className="create-page">
      <div className="content">
        <h1>Bite Size Learning</h1>
        <p className="description">Easily create fun and engaging content in just a few steps. Make a few selections and let us handle the rest!</p>
        
        {!isAuthenticated && (
          <button 
            className="auth-message-box" 
            onClick={() => setShowSignInModal(true)}
            type="button"
          >
            Please sign in to create content.
          </button>
        )}
        
        <SignInModal 
          isOpen={showSignInModal} 
          onClose={() => setShowSignInModal(false)}
          initialView="signin"
        />
      
        {isAuthenticated && (
          <form onSubmit={handleGenerate}>
            <div className="form-group">
              <label>First, choose a category that best fits your content.</label>
              <div className="category-buttons">
                {categories.map((cat) => (
                  <button
                    key={cat}
                    type="button"
                    className={`category-button ${category === cat ? 'selected' : ''}`}
                    onClick={() => setCategory(cat)}
                    disabled={!isAuthenticated}
                  >
                    {cat}
                  </button>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="topic">{getCategorySpecificContent(category).label}</label>
              <input
                type="text"
                id="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                disabled={!isAuthenticated}
              />
            </div>

            <div className="form-group">
              <label htmlFor="specificFocus">{getCategorySpecificContent(category).details}</label>
              {category === 'Time Machine' && (
                <div className="time-machine-instructions">
                  <h4>Time Machine Format Guide</h4>
                  <p>Format your request as: "{getTimeMachineInstructions().format}"</p>
                  <div className="examples-section">
                    <h5>Examples:</h5>
                    <ul>
                      {getTimeMachineInstructions().examples.map((example, index) => (
                        <li key={index} onClick={() => setSpecificFocus(example)} style={{ cursor: 'pointer' }}>
                          {example}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <p className="tip">Click any example to use it as a template</p>
                </div>
              )}
              
              {category !== 'Custom Text' && category !== 'Time Machine' && (
                <>
                  <div className="suggestions-container">
                    <span className="suggestions-text">Need some help with the details?</span>
                    <button 
                      type="button" 
                      onClick={handleGetSuggestions} 
                      className="button get-suggestions-button small-button" 
                      disabled={loadingSuggestions || !isAuthenticated || !topic || !category}
                    >
                      {loadingSuggestions ? 'Fetching...' : 'Get Suggestions'}
                    </button>
                    {loadingSuggestions && <div className="loading-spinner"></div>}
                  </div>
                  
                  {suggestions.length > 0 && (
                    <div className="suggestions-container">
                      {suggestions.map((suggestion, index) => (
                        <div key={index} className="suggestion-item">
                          <p>{suggestion}</p>
                          <button
                            type="button"
                            onClick={() => handleUseSuggestion(suggestion)}
                            className={`button use-suggestion-button ${selectedSuggestions.includes(suggestion) ? 'selected' : ''}`}
                            disabled={!isAuthenticated}
                          >
                            {selectedSuggestions.includes(suggestion) ? 'Unselect' : 'Use This'}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              
              <textarea
                id="specificFocus"
                value={specificFocus}
                onChange={handleSpecificFocusChange}
                placeholder=""
                maxLength={category === 'Custom Text' ? MAX_CHARS : 500}
                required
                disabled={!isAuthenticated}
                className={`form-textarea ${
                  category === 'Custom Text' && charCount > MAX_CHARS * 0.9 
                    ? 'near-limit' 
                    : ''
                }`}
              />
              
              {category === 'Custom Text' ? (
                <div className="text-stats">
                  <span className={charCount > MAX_CHARS * 0.9 ? 'text-red-500' : ''}>
                    {charCount}/{MAX_CHARS} characters
                  </span>
                  <span className="text-gray-500">
                    ~{timeEstimate} minute{timeEstimate !== '1' ? 's' : ''} audio
                  </span>
                </div>
              ) : (
                <p className={`char-counter ${specificFocus.length > 400 ? 'getting-full' : ''}`}>
                  {500 - specificFocus.length} characters remaining
                </p>
              )}
            </div>

            <div className="form-group">
              <label>Choose a voice</label>
              <div className="voice-options">
                {availableVoices.map(voice => (
                  <div 
                    className={`voice-card ${selectedVoice === voice.id ? 'selected' : ''}`} 
                    key={voice.id}
                  >
                    <div className="voice-info">
                      <div className="voice-avatar">{voice.avatar}</div>
                      <strong>{voice.name}</strong>
                    </div>
                    <VoicePreview 
                      voice={voice.id}
                      onSelect={(voiceId) => setSelectedVoice(voiceId)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>Add this a Playlist to help stay organized. (Optional)</label>
              <div className="playlist-controls">
                {!isCreatingPlaylist ? (
                  <>
                    <select 
                      value={selectedPlaylist}
                      onChange={(e) => setSelectedPlaylist(e.target.value)}
                      className="playlist-select"
                      disabled={!isAuthenticated}
                      style={{ backgroundColor: 'white' }}
                    >
                      <option value="" style={{ backgroundColor: 'white', color: '#666' }}>No Playlist</option>
                      {playlists && playlists.length > 0 ? (
                        playlists.map((playlist, index) => (
                          <option 
                            key={index} 
                            value={playlist}
                            style={{ backgroundColor: 'white', color: '#666' }}
                          >
                            {playlist}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>No playlists available</option>
                      )}
                    </select>
                    <button
                      type="button"
                      onClick={() => setIsCreatingPlaylist(true)}
                      className="create-playlist-button"
                      disabled={!isAuthenticated}
                    >
                      Create New Playlist
                    </button>
                  </>
                ) : (
                  <div className="new-playlist-input">
                    <input
                      type="text"
                      value={newPlaylistName}
                      onChange={(e) => setNewPlaylistName(e.target.value)}
                      placeholder="Enter playlist name"
                      className="playlist-input"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (newPlaylistName.trim()) {
                          setPlaylists([...playlists, newPlaylistName.trim()]);
                          setSelectedPlaylist(newPlaylistName.trim());
                          setNewPlaylistName('');
                        }
                        setIsCreatingPlaylist(false);
                      }}
                      className="save-playlist-button"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsCreatingPlaylist(false);
                        setNewPlaylistName('');
                      }}
                      className="cancel-playlist-button"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                Want to make this a 7-day streak?
                <ContentTypeTooltip 
                  description="This will create a seven part series delivered each morning to your channel."
                >
                  <span className="info-icon">
                    <i className="fas fa-info-circle"></i>
                  </span>
                </ContentTypeTooltip>
              </label>
              <div 
                className={`toggle-button ${isStreak ? 'active' : ''}`} 
                onClick={toggleStreak}
              >
                {isStreak ? 'Streak Active!' : 'Start 7-Day Streak'}
              </div>
              {isStreak && (
                <p className="text-sm text-gray-500 mt-2">
                  This will create a seven part series delivered each morning to your channel.
                </p>
              )}
            </div>

            <div className="form-group">
              <div className="button-container">
                <button 
                  type="submit" 
                  className="button generate-button" 
                  disabled={loadingGenerate || isLoading || !isAuthenticated}
                >
                  {loadingGenerate ? 'Generating...' : 'Create Audio'}
                </button>

                {contentReady && (
                  <button className="button content-ready-button" onClick={handleNavigateToProfile}>
                    {isStreak ? 'STREAK STARTED! CLICK HERE TO GET STARTED' : 'ALL DONE! CLICK HERE TO LISTEN'}
                  </button>
                )}
              </div>

              {loadingGenerate && !contentReady && (
                <div className="animation-container" ref={animationContainer}></div>
              )}

              {loadingGenerate && !contentReady && (
                <div className="text-animation">Generating your Biglio Content</div>
              )}

              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={getRecaptchaSiteKey()}
              />

              <p className="recaptcha-terms">
                This site is protected by reCAPTCHA and the Google{' '}
                <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and{' '}
                <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
              </p>
            </div>
          </form>
        )}

        {error && <p className="error">{error}</p>}
        
        {showSignInModal && (
          <SignInModal onClose={() => setShowSignInModal(false)} />
        )}
        
        {showVoices && (
          <div className="voices-overlay">
            <Voices onClose={() => setShowVoices(false)} />
          </div>
        )}
        {showSpicyOverlay && (
  <SpicyOverlay 
    message={spicyMessage}
    onClose={() => setShowSpicyOverlay(false)}
  />
)}
      </div>
    </div>
  );
}

export default CreatePage;