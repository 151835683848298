import React, { useState } from 'react';

const ContentTypeTooltip = ({ children, description }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className="absolute z-50 w-64 p-2 text-sm bg-gray-800 text-white rounded shadow-lg mt-1">
          {description}
        </div>
      )}
    </div>
  );
};

export default ContentTypeTooltip;