import React from 'react';
import { useViewportSize } from '../hooks/useViewportSize';

const ProfileImage = ({ imageUrl, defaultImage }) => {
  const isMobile = useViewportSize();
  
  // Construct the image URL with size parameter
  const getImageUrl = (url) => {
    if (!url) return defaultImage;
    const width = isMobile ? 80 : 120;
    // Check if URL already has query parameters
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}width=${width}`;
  };

  return (
    <div className="profile-image-container">
      <img
        src={getImageUrl(imageUrl)}
        alt="Profile"
        className="profile-image"
        width={isMobile ? 80 : 120}
        height={isMobile ? 80 : 120}
      />
    </div>
  );
};

export default ProfileImage; 