console.log('Moderation module loaded');

// Only strictly banned words/phrases related to adult content and exploitation
const STRICT_BANNED_WORDS = [
  'porn', 'xxx', 'pornography',
  'child abuse', 'pedophile', 'molest'
];

// Much simpler context checking for potentially sensitive terms
const CONTEXT_WORDS = {
  'sex': {
    allowedPrefixes: ['sus', 'middle', 'es'],
    allowedSuffixes: ['education', 'cells', 'determination', 'linked', 'chromosomes']
  }
};

const FUNNY_REJECTIONS = [
  "Whoa there! Let's keep things family-friendly 😅",
  "That's a bit spicy for our taste! Try something milder 🌶️",
  "Sorry, we're keeping it PG here! 🎬",
  "Let's stick to more wholesome topics 🌈",
  "That's not quite our cup of tea ☕",
  "We're all about good vibes only! 🌟",
  "How about something more suitable for all ages? 👨‍👩‍👧‍👦",
  "Let's keep it clean and friendly! 🧼"
];

export const checkContent = (topic, specificFocus) => {
  const combinedText = `${topic} ${specificFocus}`.toLowerCase();
  
  console.log('Checking content:', { topic, specificFocus });
  
  // First check strict banned words
  const foundStrictBannedWord = STRICT_BANNED_WORDS.find(word => {
    const regex = new RegExp(`\\b${word}\\b`, 'i');
    const isFound = regex.test(combinedText);
    if (isFound) {
      console.log('Found banned word:', word);
    }
    return isFound;
  });

  if (foundStrictBannedWord) {
    return {
      isValid: false,
      message: FUNNY_REJECTIONS[Math.floor(Math.random() * FUNNY_REJECTIONS.length)]
    };
  }

  // Only check sex in educational/scientific context
  if (combinedText.includes('sex')) {
    console.log('Found "sex", checking context');
    const words = combinedText.split(/\s+/);
    const wordIndex = words.findIndex(w => w === 'sex');
    
    if (wordIndex !== -1) {
      const prevWord = wordIndex > 0 ? words[wordIndex - 1] : '';
      const nextWord = wordIndex < words.length - 1 ? words[wordIndex + 1] : '';
      
      console.log('Context:', { prevWord, nextWord });

      const isAllowedContext = 
        CONTEXT_WORDS.sex.allowedPrefixes.some(prefix => prevWord.includes(prefix)) ||
        CONTEXT_WORDS.sex.allowedSuffixes.some(suffix => nextWord.includes(suffix));

      if (!isAllowedContext) {
        console.log('Rejected due to context');
        return {
          isValid: false,
          message: FUNNY_REJECTIONS[Math.floor(Math.random() * FUNNY_REJECTIONS.length)]
        };
      }
    }
  }
  
  console.log('Content passed moderation');
  return { isValid: true, message: null };
};

export const SpicyOverlay = ({ message, onClose }) => {
  return (
    <div className="spicy-overlay">
      <div className="spicy-content">
        <h3>Oops! 🌶️</h3>
        <p>{message}</p>
        <button onClick={onClose}>Try Again</button>
      </div>
    </div>
  );
};