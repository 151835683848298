import React, { useState, useRef } from 'react';  
import { useNavigate } from 'react-router-dom';
import SignInModal from './SignInModal';
import { MessageSquare, Mic, Calendar, Brain, Folder, Share2, Play, Pause, Sparkles, Radio, QrCode, FolderTree } from 'lucide-react';
import '../styles/HomePageV4.css';
import emailjs from 'emailjs-com';
import { getDemoAudioUrl } from '../config/api';
import { getEmailServiceId, getEmailTemplateId, getEmailUserId } from '../config/email';

const HomePage = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleStartCreating = () => {
    navigate('/create');
  };

  const sendFeedback = (e) => {
    e.preventDefault();

    emailjs.sendForm(getEmailServiceId(), getEmailTemplateId(), e.target, getEmailUserId())
      .then((result) => {
          console.log(result.text);
          alert('Feedback sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send feedback. Please try again.');
      });

    e.target.reset();
  };

  return (
    <div className="home-container">
      <article itemScope itemType="http://schema.org/WebPage">
        <div className="inner-container">
          <main>
            <header className="hero-section">
              <div className="content-wrapper">
                <div className="left-section">
                  <h1 className="section-title">
                    Welcome to Biglio
                    <br />
                    <span className="gradient-text">Discover. Learn. Create.</span>
                  </h1>
                  <meta itemProp="description" content="AI-powered audio content creation platform" />
                  <p className="hero-description">
                    Biglio transforms your thoughts into engaging, bite-sized audio content. From bedtime stories to deep dives into fascinating topics, learning has never been this effortless—or this personalized.
                    <br /><br />
                    ✨ Sign up now (it's free!) and join our community of creators and innovators. Everything you’re curious about is at your fingertips—and in your ears.
                  </p>
                  <button 
                    className="cta-button"
                    onClick={handleStartCreating}
                  >
                    Start Creating
                  </button>
                </div>
                
                <div className="right-section">
                  <div className="audio-player-card">
                    <div className="audio-header">
                      <div className="icon-box">
                        <Sparkles className="icon" />
                      </div>
                      <div>
                        <h3 className="audio-title">What is BIGLIO?</h3>
                        <p className="audio-duration">1:30 mins</p>
                      </div>
                    </div>

                    <audio 
                      ref={audioRef}
                      src={getDemoAudioUrl()}
                      onEnded={() => setIsPlaying(false)}
                    />

                    <div className="waveform-container">
                      <div className="waveform">
                        {[...Array(50)].map((_, i) => (
                          <div
                            key={i}
                            style={{
                              height: `${Math.random() * 100}%`,
                              opacity: isPlaying ? 1 : 0.5,
                            }}
                            className="wave-bar"
                          />
                        ))}
                      </div>
                    </div>

                    <button
                      onClick={togglePlay}
                      className="play-button"
                    >
                      {isPlaying ? <Pause className="icon" /> : <Play className="icon" />}
                      {isPlaying ? 'Pause' : 'Play'}
                    </button>
                  </div>
                </div>
              </div>
            </header>

            {/* Features Grid */}
            <section className="features-section" itemScope itemType="http://schema.org/ItemList">
              <meta itemProp="name" content="Biglio Features" />
              <div className="inner-container">
                <h2 className="section-title">
                  AI-Powered Features
                </h2>
                
                <div className="features-grid">
                  {/* AI Content Creation */}
                  <div className="feature-card">
                    <Brain className="feature-icon" />
                    <h3>AI Content Creation</h3>
                    <p>Transform your ideas into professional audio content in minutes. Choose from multiple topics and expert voices.</p>
                  </div>

                  {/* Channels */}
                  <div className="feature-card">
                    <Radio className="feature-icon" />
                    <h3>Content Channels</h3>
                    <p>Build your channel, grow your audience, and connect with listeners. Share knowledge your way.</p>
                  </div>

                  {/* Learning Streaks */}
                  <div className="feature-card">
                    <Calendar className="feature-icon" />
                    <h3>Content Series</h3>
                    <p>Create engaging week-long series. Perfect for courses, stories, or daily insights delivered to your audience.</p>
                  </div>

                  {/* Smart Chat */}
                  <div className="feature-card">
                    <MessageSquare className="feature-icon" />
                    <h3>Smart AI Chat</h3>
                    <p>Your AI assistant remembers context and helps organize ideas. Reference past discussions with our smart filing system.</p>
                  </div>

                  {/* QR Integration */}
                  <div className="feature-card">
                    <QrCode className="feature-icon" />
                    <h3>QR Integration</h3>
                    <p>Connect physical products to digital content. Share product info, stories, and instructions through scannable QR codes.</p>
                  </div>

                  {/* Organization */}
                  <div className="feature-card">
                    <FolderTree className="feature-icon" />
                    <h3>Smart Organization</h3>
                    <p>Keep your content organized with nested folders, playlists, and tags. Find anything in seconds.</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Use Cases Section */}
            <section className="use-cases-section" itemScope itemType="http://schema.org/ItemList">
              <meta itemProp="name" content="Biglio Use Cases" />
              <div className="inner-container">
                <h2 className="section-title">
                  Create Anything
                </h2>
                
                <div className="use-cases-grid">
                  <div className="use-case-card">
                    <h3>Education</h3>
                    <p>Create engaging tutorials, explanations, and courses. Perfect for teachers, experts, and thought leaders.</p>
                  </div>
                  
                  <div className="use-case-card">
                    <h3>Entertainment</h3>
                    <p>Produce stories, narratives, and creative content. Bring your imagination to life through audio.</p>
                  </div>
                  
                  <div className="use-case-card">
                    <h3>Business</h3>
                    <p>Share industry insights, product information, and professional content. Connect with customers through audio.</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="beta-message">
              <p>
                🚀 Help Shape the Future of Biglio
                <br /><br />
                We're building something special and your feedback matters. Tell us what features you'd love to see, what could work better, or share your ideas for making Biglio even more amazing.
              </p>
            </section>

            <section className="feedback-form">
              <h3>Share Your Thoughts</h3>
              <form onSubmit={sendFeedback}>
                <input type="text" name="from_name" placeholder="Your name" required />
                <textarea 
                  name="message" 
                  placeholder="What features would you like to see? How can we make Biglio better for you?" 
                  required
                ></textarea>
                <input type="email" name="user_email" placeholder="Your email" required />
                <button type="submit">Send Feedback</button>
              </form>
            </section>
          </main>
        </div>
      </article>

      <SignInModal isOpen={false} onClose={() => {}} />
    </div>
  );
};

export default HomePage;