import React, { useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Printer, X } from 'lucide-react';
import '../styles/QRCodeModal.css';

const QRCodeModal = ({ url, onClose }) => {
  const qrRef = useRef(null);

  const handlePrint = () => {
    if (!qrRef.current) return;

    // Check if it's a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // For mobile, open in new window
      const printWindow = window.open('', '_blank');
      if (!printWindow) return;

      // Get the SVG element and clone it
      const svgElement = qrRef.current.querySelector('svg');
      if (!svgElement) return;

      const svgClone = svgElement.cloneNode(true);
      const svgData = new XMLSerializer().serializeToString(svgClone);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);

      const printContent = `
        <!DOCTYPE html>
        <html>
          <head>
            <title>Print QR Code</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>
              body {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
                background: white;
              }
              .print-container {
                width: 100%;
                max-width: 90vw;
                margin: auto;
                text-align: center;
              }
              img {
                width: 100%;
                height: auto;
                max-width: 100%;
                display: block;
                margin: 0 auto;
              }
              @media print {
                body {
                  -webkit-print-color-adjust: exact;
                  print-color-adjust: exact;
                }
                .print-container {
                  position: fixed;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            </style>
          </head>
          <body>
            <div class="print-container">
              <img src="${svgUrl}" alt="QR Code" />
            </div>
            <script>
              document.addEventListener('DOMContentLoaded', function() {
                setTimeout(() => {
                  window.print();
                  setTimeout(() => {
                    window.close();
                  }, 1000);
                }, 500);
              });
            </script>
          </body>
        </html>
      `;

      printWindow.document.write(printContent);
      printWindow.document.close();

      // Clean up
      setTimeout(() => {
        URL.revokeObjectURL(svgUrl);
      }, 2000);
    } else {
      // For desktop, use hidden iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      const svgElement = qrRef.current.querySelector('svg');
      if (!svgElement) return;

      const svgClone = svgElement.cloneNode(true);
      const svgData = new XMLSerializer().serializeToString(svgClone);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);

      const printContent = `
        <!DOCTYPE html>
        <html>
          <head>
            <title>Print QR Code</title>
            <style>
              body {
                margin: 0;
                padding: 0;
              }
              .print-container {
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                box-sizing: border-box;
              }
              img {
                width: 100%;
                max-width: 400px;
                height: auto;
              }
            </style>
          </head>
          <body>
            <div class="print-container">
              <img src="${svgUrl}" alt="QR Code" />
            </div>
            <script>
              window.onload = () => {
                window.print();
                setTimeout(() => window.close(), 100);
              };
            </script>
          </body>
        </html>
      `;

      iframe.contentDocument.write(printContent);
      iframe.contentDocument.close();

      // Clean up
      setTimeout(() => {
        URL.revokeObjectURL(svgUrl);
        document.body.removeChild(iframe);
      }, 1000);
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSMSShare = () => {
    window.location.href = `sms:?&body=${encodeURIComponent(`Check this out: ${url}`)}`;
  };

  return (
    <div className="qr-modal-overlay">
      <div 
        className="qr-modal-backdrop"
        onClick={onClose}
      />
      <div className="qr-modal-container">
        <div className="qr-modal-content">
          <div className="qr-modal-header">
            <h3 className="qr-modal-title">
              Share QR Code
            </h3>
            <button
              onClick={onClose}
              className="qr-modal-close"
            >
              ✕
            </button>
          </div>
          
          <div className="qr-code-container">
            <div className="qr-code" ref={qrRef}>
              <QRCodeSVG
                value={url}
                size={200}
                level="H"
                includeMargin={true}
                bgColor="#FFFFFF"
                fgColor="#000000"
              />
            </div>
           
            <button
              onClick={handlePrint}
              className="qr-print-button"
            >
              <Printer size={20} />
              Print QR Code
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;