import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../styles/Navbar.css';
import toast from 'react-hot-toast';

const Navbar = ({ onSignInClick, onSignUpClick }) => {
  const { isAuthenticated, signOut, user } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    if (user) {
      const userUsername = user.username || 
                          user.attributes?.username || 
                          user.signInUserSession?.idToken?.payload?.username || 
                          user.signInUserSession?.idToken?.payload['cognito:username'];
      
      if (userUsername) {
        setUsername(userUsername);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isMobileMenuOpen) return;

    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleSignOut = () => {
    signOut();
    closeMobileMenu();
    navigate('/');
  };

  const handleMyChannelClick = (e) => {
    if (!username) {
      e.preventDefault();
      return;
    }
    closeMobileMenu();
  };

  const handleProtectedLink = (e, path) => {
    if (!isAuthenticated) {
      e.preventDefault();
      toast.error('Please sign in to access this feature', {
        duration: 2000,
        style: {
          background: '#333',
          color: '#fff',
          border: '1px solid #444',
        },
      });
      onSignInClick();
      closeMobileMenu();
      return;
    }
    navigate(path);
    closeMobileMenu();
  };

  return (
    <div className="nav-container">
      <nav className="navbar" ref={mobileMenuRef}>
        <div className="navbar-logo">
          <Link to="/" onClick={closeMobileMenu}>Biglio</Link>
        </div>
        <div className="navbar-toggle" onClick={toggleMobileMenu}>
          <span className="navbar-toggle-icon">&#9776;</span>
        </div>
        <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={closeMobileMenu}>HOME</Link></li>
          <li><Link to="/create" onClick={() => {
            closeMobileMenu();
            if (!isAuthenticated) {
              onSignInClick();
              return;
            }
          }}>CREATE</Link></li>
          <li>
            <a 
              href="#" 
              onClick={(e) => handleProtectedLink(e, '/biglio-chat')}
              className="nav-link"
            >
              CHAT
            </a>
          </li>
          <li>
            {isAuthenticated ? (
              <Link 
                to={`/channel/${username}`}
                onClick={handleMyChannelClick}
                className="nav-link"
              >
                MY CHANNEL
              </Link>
            ) : (
              <a 
                href="#" 
                onClick={(e) => handleProtectedLink(e, '/my-channel')}
                className="nav-link"
              >
                MY CHANNEL
              </a>
            )}
          </li>
          {isAuthenticated ? (
            <li><button onClick={handleSignOut} className="nav-button">SIGN OUT</button></li>
          ) : (
            <li>
              <button 
                onClick={() => { onSignInClick(); closeMobileMenu(); }}
                className="try-free-button"
              >
                SIGN IN
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;