import React, { useState, useRef, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCopy, 
  faUser, 
  faFolderPlus, 
  faFolder, 
  faBars, 
  faTimes,
  faPencilAlt,
  faTrash,
  faGripVertical,
  faStar as faSolidStar, 
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faRegularStar } from '@fortawesome/free-regular-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactMarkdown from 'react-markdown';
import { AuthContext } from '../../context/AuthContext';
import ChatInput from './ChatInput';
import SignInModal from '../../components/SignInModal';
import '../styles/ChatContainer.css';
import { getChatEndpoint } from '../../config/api';

const ChatContainer = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const messagesEndRef = useRef(null);
  const lastUserMessageRef = useRef(null);
  const [starredMessages, setStarredMessages] = useState(new Set());
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Folder states
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isFolderSidebarOpen, setIsFolderSidebarOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [folderConversations, setFolderConversations] = useState({});
  const [isFolderLoading, setIsFolderLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [editName, setEditName] = useState('');

     // Initial load effects
  useEffect(() => {
    if (user) {
      fetchFolders();
    }
  }, [user]);

  useEffect(() => {
    // Restore selected folder on mount
    const savedFolder = localStorage.getItem('selectedFolder');
    if (savedFolder && user) {
      const folder = JSON.parse(savedFolder);
      handleSelectFolder(folder);
    }
  }, [user]);

  useEffect(() => {
    if (currentFolder) {
      localStorage.setItem('selectedFolder', JSON.stringify(currentFolder));
    }
  }, [currentFolder]);

  // Load starred messages
  useEffect(() => {
    const loadStarredMessages = async () => {
      try {
        const response = await fetch(getChatEndpoint(), {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            action: 'getStarredMessages',
            userId: user.userid
          })
        });

        if (!response.ok) throw new Error('Failed to fetch starred messages');
        
        const data = await response.json();
        setStarredMessages(new Set(data.messages.map(m => m.id)));
      } catch (error) {
        setError('Failed to load starred messages: ' + error.message);
      }
    };

    if (user) {
      loadStarredMessages();
    }
  }, [user]);

  // Message action handlers
  const copyMessage = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setError('Failed to copy message');
    }
  };

  const handleStarMessage = async (message) => {
    try {
      const action = starredMessages.has(message.id) ? 'unstarMessage' : 'starMessage';
      
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action,
          userId: user.userid,
          messageId: message.id,
          conversationId: conversationId,
          content: message.content,
          folderId: currentFolder?.id
        })
      });

      if (!response.ok) throw new Error('Failed to update star status');

      setStarredMessages(prev => {
        const newSet = new Set(prev);
        if (action === 'starMessage') {
          newSet.add(message.id);
        } else {
          newSet.delete(message.id);
        }
        return newSet;
      });
    } catch (error) {
      setError('Failed to update star status: ' + error.message);
    }
  };

  const handleHumanize = async (content, messageId) => {
    try {
      setIsLoading(true);
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'chat',
          message: content,
          userId: user.userid,
          humanize: true
        })
      });

      if (!response.ok) throw new Error('Failed to humanize message');
      
      const data = await response.json();
      
      setMessages(prev => prev.map(msg => 
        msg.id === messageId ? { ...msg, content: data.chunk } : msg
      ));
    } catch (error) {
      setError('Failed to humanize message: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFolders = async () => {
    try {
      setIsFolderLoading(true);
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getFolders',
          userId: user.userid
        })
      });
      
      if (!response.ok) throw new Error('Failed to fetch folders');
      const data = await response.json();
      setFolders(data.folders || []);
    } catch (error) {
      setError('Failed to fetch folders: ' + error.message);
    } finally {
      setIsFolderLoading(false);
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;

    try {
      setIsFolderLoading(true);
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'createFolder',
          userId: user.userid,
          folderName: newFolderName.trim()
        })
      });
      
      if (!response.ok) throw new Error('Failed to create folder');
      
      const newFolder = await response.json();
      setFolders(prev => [...prev, newFolder]);
      setNewFolderName('');
      setCurrentFolder(newFolder);
    } catch (error) {
      setError('Failed to create folder: ' + error.message);
    } finally {
      setIsFolderLoading(false);
    }
  };

  const handleSelectFolder = async (folder) => {
    setCurrentFolder(folder);
    setIsFolderSidebarOpen(false);
    
    try {
      setIsFolderLoading(true);
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'getFolderConversations',
          userId: user.userid,
          folderId: folder.id
        })
      });
      
      if (!response.ok) throw new Error('Failed to fetch conversations');
      const data = await response.json();
      
      // Update the folder conversations cache
      setFolderConversations(prev => ({
        ...prev,
        [folder.id]: data.conversations || []
      }));
      
      // Set the current conversation if there are any
      if (data.conversations && data.conversations.length > 0) {
        const latestConversation = data.conversations[0]; // Assuming sorted by newest first
        setConversationId(latestConversation.id);
        setMessages(latestConversation.messages || []);
      } else {
        setMessages([]);
        setConversationId(null);
      }
    } catch (error) {
      setError('Failed to fetch conversations: ' + error.message);
    } finally {
      setIsFolderLoading(false);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(folders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFolders(items);

    try {
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'updateFolderOrder',
          userId: user.userid,
          folders: items.map((folder, index) => ({
            id: folder.id,
            order: index
          }))
        })
      });

      if (!response.ok) throw new Error('Failed to update folder order');
    } catch (error) {
      setError('Failed to update folder order: ' + error.message);
    }
  };

  const handleRename = async (folder) => {
    if (!editName.trim()) return;
    
    try {
      setIsFolderLoading(true);
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'renameFolder',
          userId: user.userid,
          folderId: folder.id,
          newName: editName.trim()
        })
      });

      if (!response.ok) throw new Error('Failed to rename folder');
      
      setFolders(prev => prev.map(f => 
        f.id === folder.id ? { ...f, name: editName.trim() } : f
      ));
      setIsEditing(null);
      setEditName('');
    } catch (error) {
      setError('Failed to rename folder: ' + error.message);
    } finally {
      setIsFolderLoading(false);
    }
  };

  const handleDelete = async (folder) => {
    if (!window.confirm(`Are you sure you want to delete "${folder.name}"?`)) return;

    try {
      setIsFolderLoading(true);
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'deleteFolder',
          userId: user.userid,
          folderId: folder.id
        })
      });

      if (!response.ok) throw new Error('Failed to delete folder');
      
      setFolders(prev => prev.filter(f => f.id !== folder.id));
      if (currentFolder?.id === folder.id) {
        setCurrentFolder(null);
        setMessages([]);
      }
    } catch (error) {
      setError('Failed to delete folder: ' + error.message);
    } finally {
      setIsFolderLoading(false);
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim() || !user) return;

    setError(null);
    const newMessageId = `MSG#${Date.now()}`;
    const newMessage = {
      id: newMessageId,
      role: 'user',
      content: message,
      timestamp: Date.now()
    };

    setMessages(prev => [...prev, newMessage]);
    setIsLoading(true);
    setCurrentStreamingMessage('');

    try {
      const response = await fetch(getChatEndpoint(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'chat',
          message,
          userId: user.userid,
          conversationId,
          folderId: currentFolder?.id
        })
      });

      if (!response.ok) throw new Error('Failed to send message');

      const data = await response.json();
      
      if (data.chunk) {
        const assistantMessage = {
          id: data.messageId || Date.now(),
          role: 'assistant',
          content: data.chunk,
          timestamp: Date.now()
        };

        setMessages(prev => [...prev, assistantMessage]);
        setCurrentStreamingMessage('');
        
        if (!conversationId && data.conversationId) {
          setConversationId(data.conversationId);
        }
        
        if (currentFolder) {
          setFolderConversations(prev => ({
            ...prev,
            [currentFolder.id]: [
              { id: data.conversationId, messages: [...messages, newMessage, assistantMessage] },
              ...(prev[currentFolder.id] || [])
            ]
          }));
        }
      }

      // After sending, scroll to show both user message and start of response
      setTimeout(() => {
        if (lastUserMessageRef.current) {
          lastUserMessageRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100);

    } catch (error) {
      console.error('Error sending message:', error);
      setError(`Failed to send message: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToMessage = (messageId) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      messageElement.classList.add('highlighted');
      setTimeout(() => messageElement.classList.remove('highlighted'), 2000);
    }
  };
  
  // Replace this useEffect with the new scrolling logic
  useEffect(() => {
    const scrollToLatestInteraction = () => {
      if (lastUserMessageRef.current && (isLoading || messages.length > 0)) {
        lastUserMessageRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'  // This will align the top of the message with the top of the container
        });
      }
    };
  
    const scrollForTypingIndicator = () => {
      const messagesContainer = document.querySelector('.messages-container');
      if (messagesContainer && isLoading) {
        const scrollPosition = messagesContainer.scrollHeight - messagesContainer.clientHeight - 100; // Adjusted offset
        messagesContainer.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }
    };
  
    if (isLoading) {
      scrollForTypingIndicator();
    }
  
    // Scroll when new messages are added or when loading state changes
    scrollToLatestInteraction();

  }, [messages, isLoading, currentStreamingMessage]); 
  
  const toggleFolderSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };
  
  const scrollToBottom = () => {
    if (window.innerWidth <= 768) {  // Mobile view
      // Find the last user message and scroll to show it plus some of the AI response
      if (lastUserMessageRef.current) {
        lastUserMessageRef.current.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'  // Align to top to show user message and start of AI response
        });
      }
    } else {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
        if (user) {
            try {
                const response = await fetch(getChatEndpoint(), {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        action: 'initializeUser',
                        userId: user.userid
                    })
                });

                if (!response.ok) throw new Error('Failed to initialize user');
                
                const data = await response.json();
                if (data.id) {
                    // If a new default folder was created, select it
                    handleSelectFolder({
                        id: data.id,
                        name: data.name,
                        isDefault: true
                    });
                }
            } catch (error) {
                console.error('Error initializing user:', error);
            }
        }
    };

    initializeUser();
  }, [user]); // Run when user is loaded

  if (!isAuthenticated) {
    return (
      <div>
        <button onClick={() => setShowSignInModal(true)}>Sign In to Access Chat</button>
        {showSignInModal && <SignInModal onClose={() => setShowSignInModal(false)} />}
      </div>
    );
  }

  // Main return with chat layout
return (
  <div className="chat-layout">
  <div className={`folder-sidebar ${isSidebarOpen ? 'active' : ''}`}>
  <button 
    className="close-sidebar-button"
    onClick={() => setIsSidebarOpen(false)}
    aria-label="Close sidebar"
  >
    <FontAwesomeIcon icon={faTimes} size="lg" />
  </button>
  <div className="folder-management">
        <h3>Folders</h3>
        <div className="new-folder-input">
          <input
            type="text"
            className="folder-input"
            placeholder="New folder name"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleCreateFolder();
              }
            }}
            disabled={isFolderLoading}
          />
          <button
            className="create-folder-btn"
            onClick={handleCreateFolder}
            disabled={isFolderLoading}
          >
            <FontAwesomeIcon icon={faFolderPlus} />
          </button>
        </div>
        <div className="folder-list">
          {isFolderLoading ? (
            <div className="loading-indicator">Loading...</div>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="folders">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {folders.map((folder, index) => (
                      <Draggable
                        key={folder.id}
                        draggableId={folder.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`folder-item ${currentFolder?.id === folder.id ? 'selected' : ''} ${
                              snapshot.isDragging ? 'dragging' : ''
                            }`}
                          >
                            {isEditing === folder.id ? (
                              <div className="folder-edit">
                                <input
                                  type="text"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      handleRename(folder);
                                    }
                                  }}
                                  onBlur={() => {
                                    setIsEditing(null);
                                    setEditName('');
                                  }}
                                  autoFocus
                                />
                              </div>
                            ) : (
                              <div className="folder-content">
                                <div {...provided.dragHandleProps} className="drag-handle">
                                  <FontAwesomeIcon icon={faGripVertical} />
                                </div>
                                <div className="folder-name" onClick={() => handleSelectFolder(folder)}>
                                  <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                                  <span>{folder.name}</span>
                                </div>
                                <div className="folder-actions">
                                  <button
                                    className="folder-action-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsEditing(folder.id);
                                      setEditName(folder.name);
                                    }}
                                    title="Rename folder"
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </button>
                                  <button
                                    className="folder-action-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDelete(folder);
                                    }}
                                    title="Delete folder"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>

           {/* Starred Messages Section Below Folders */}
           <div className="starred-messages-section">
        <h3>Starred Messages</h3>
        <div className="starred-list">
          {Array.from(starredMessages).map(messageId => {
            const message = messages.find(m => m.id === messageId);
            if (!message) return null;
            return (
              <div 
                key={messageId} 
                className="starred-message-item"
              >
                <div className="starred-message-content" onClick={() => scrollToMessage(messageId)}>
                  <FontAwesomeIcon icon={faSolidStar} className="star-icon" />
                  <div className="starred-message-preview">
                    {message.content.substring(0, 50)}...
                  </div>
                </div>
                <button
                  className="unstar-btn"
                  onClick={() => handleStarMessage(message)}
                  title="Remove from starred"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>

    <div className="chat-container">
  <div className="chat-header">
    <div className="header-content">
      <button 
        className="toggle-folder-button"
        onClick={toggleFolderSidebar}
        disabled={isFolderLoading}
      >
        <FontAwesomeIcon icon={isFolderSidebarOpen ? faTimes : faBars} />
      </button>
      <h2>Hi! I'm Biglio. How can I help you today?</h2>
    </div>
    {currentFolder && (
      <div className="current-folder-indicator">
        Current folder: <span className="folder-name">{currentFolder.name}</span>
      </div>
    )}
    {error && (
      <div className="error-banner">
        {error}
        <button onClick={() => setError(null)} className="error-dismiss">×</button>
      </div>
    )}
  </div>

  <div className="messages-container">
  <div className="messages">
    {messages.map((message, index) => (
      // Only show messages that aren't the last one when loading
      (!isLoading || index !== messages.length - 1) && (
        <div 
          key={message.id} 
          id={`message-${message.id}`}
          className={`message ${message.role}`}
          ref={index === messages.length - 2 ? lastUserMessageRef : null}  // Reference the second-to-last message (user's question)
        >
          <div className="message-content">
            {message.role === 'assistant' ? (
              <ReactMarkdown>{message.content}</ReactMarkdown>
            ) : (
              message.content
            )}
            {message.role === 'assistant' && (
              <div className="message-actions">
                <button
                  className="copy-icon"
                  onClick={() => copyMessage(message.content)}
                  title="Copy message"
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
                <button
                  className="humanize-icon"
                  onClick={() => handleHumanize(message.content, message.id)}
                  title="Make more human-like"
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={faUser} />
                </button>
                <button
                  className={`star-btn ${starredMessages.has(message.id) ? 'starred' : ''}`}
                  onClick={() => handleStarMessage(message)}
                  title={starredMessages.has(message.id) ? "Unstar message" : "Star message"}
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={starredMessages.has(message.id) ? faSolidStar : faRegularStar} />
                </button>
              </div>
            )}
          </div>
        </div>
      )
    ))}
    
    {isLoading && messages.length > 0 && (
      <>
        <div className="message user">
          <div className="message-content">
            {messages[messages.length - 1].content}
          </div>
        </div>
        <div className="typing-indicator-container">
          <div className="typing-indicator">
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
          </div>
        </div>
      </>
    )}
    
    {currentStreamingMessage && (
      <div className="message assistant">
        <div className="message-content">
          <ReactMarkdown>{currentStreamingMessage}</ReactMarkdown>
        </div>
      </div>
    )}
    <div ref={messagesEndRef} />
  </div>
</div>

<div className="chat-input-container">
  {isLoading && messages.length > 0 && (
    <div className="typing-indicator-container">
      <div className="typing-indicator">
        <div className="typing-dot"></div>
        <div className="typing-dot"></div>
        <div className="typing-dot"></div>
      </div>
    </div>
  )}
  <ChatInput
    onSendMessage={handleSendMessage}
    disabled={isLoading || isFolderLoading}
    placeholder="Hi! I'm Biglio, how can I help you?"
  />
</div>
    </div>
  </div>
);
};

export default ChatContainer;